<template>
  <card class="adsfree__main-card padding-1">
    <h6 class="policy-card-title">
        <svgicon class="icon" icon="rule-actions" width="16" height="16" />
        <span style="padding-left:1em">{{t('rule actions')}}</span>
    </h6>
    <div class="row space-around">
      <el-form-item class="form-item main-label-position" :label="t('status')">
        <div class="col-md-12 item-alignment">
          <n-switch
            v-model='status'
            @input="handleChange($event, 'status')"
            color="allot"
          ></n-switch>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item main-label-position" :label="t('action')" prop='action'>
        <div class="col-md-12 item-alignment">
          <el-select
            :value="rule.action.type"
            placeholder="Select an action"
            @input="handleChange($event, 'type')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in actionOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </div>
    <div v-if="rule.action.type === 'other'" class="row space-around">
      <el-form-item class="form-item" :label="t('block-only-ads-pop-ups')">
        <div class="col-md-12 item-alignment">
          <el-checkbox
            :value='rule.action.other.block_only_ads_pop_ups'
            @input="handleChangeOtherActions($event, 'block_only_ads_pop_ups')"
            placeholder='block-only-ads-pop-ups'
          ></el-checkbox>
        </div>
      </el-form-item>
      <el-form-item class="form-item" :label="t('freeze-gifs-animation')">
        <div class="col-md-12 item-alignment">
          <el-checkbox
            :value='rule.action.other.freeze_gifs_animation'
            @input="handleChangeOtherActions($event, 'freeze_gifs_animation')"
            placeholder='freeze-gifs-animation'
          ></el-checkbox>
        </div>
      </el-form-item>
      <el-form-item class="form-item" :label="t('hide-banners')">
        <div class="col-md-12 item-alignment">
          <el-checkbox
            :value='rule.action.other.hide_banners'
            @input="handleChangeOtherActions($event, 'hide_banners')"
            placeholder='hide-banners'
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="140px" :label="t('log activity')">
        <div class="col-md-12 item-alignment">
          <el-checkbox
            :value='rule.log_activity'
            @input="handleChange($event, 'log_activity')"
            placeholder='Log activity'
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
    <!-- Apply policy only during client connection -->
    <div class="row space-around">
      <el-form-item class="form-item" :label="t('apply policy only during client connection')">
        <div class="col-md-12 item-alignment">
          <el-checkbox
            :value='rule.onlyDuringConnection'
            @input="handleChange($event, 'onlyDuringConnection')"
            placeholder='Apply policy only during client connection'
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
  </card>
</template>

<script>
  import { FormItem, Checkbox, Select, Option, InputNumber } from 'element-ui';
  import Switch from '@/components/Switch';
  import _ from 'lodash';
  export default {
    name: 'main-anti-phishing-config-card',
    components: {
      'el-checkbox': Checkbox,
      'el-form-item': FormItem,
      'n-switch': Switch,
      'el-select': Select,
      'el-option': Option,
      'el-input-number': InputNumber
    },
    data() {
      return {
        actionOptions: [
          {value: 'other', label: 'Select other actions'},
          {value: 'deny', label: 'Deny'},
          {value: 'bypass', label: 'Bypass'},
        ]
      }
    },
    props: {
      rule: {
        type: Object,
        default: () => {
          return {
            log_activity: true,
            status: false,
            action: {type: 'bypass'},
            event: {status: false},
            onlyDuringConnection: false
          }
        }
      }
    },
    computed: {
      status: {
        get() { return this.rule.status },
        set(newRuleStatus) {
          let updatedRule = _.cloneDeep(this.rule);
          updatedRule.status = newRuleStatus;
          this.$emit('rule-change', updatedRule);
        }
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        if (input === 'type') {
          updatedRule.action[input] = event;
          updatedRule.action.value = undefined;
        } else if (input === 'value') updatedRule.action.value = event;
        else if (input === 'event') updatedRule.event.status = event;
        else if (input === 'name') updatedRule.event.name = event;
        else updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
      },
      handleChangeOtherActions(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        updatedRule.action.other[input] = event;
        this.$emit('rule-change', updatedRule);
      }
    }
  }
</script>

<style lang='scss'>
  .adsfree__main-card.card {
    font-family: "AmpleSoft Pro Medium", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    border-radius: 0.5em;
    .card-body { margin-top: 1em; }
    .form-item { width: 70%; }
    @media (max-width: 1700px) {
      .main-label-position {
        label {
          width: auto!important;
          float: none;
          display: inline-block;
          text-align: left;
          padding: 0;
          margin: 0;
        }
        div { margin-left: 0!important; }
      }
    }
  }
</style>
