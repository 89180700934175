<template>
  <service-policy-edit
    serviceName="autonotices"
    storeModuleName="autoNotices"
    pathName="AutoNotices"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
  >
    <template v-slot:main-card="slotProps">
      <main-autonotices-config-card
        :rule='slotProps.rule'
        @rule-change='slotProps.handleRuleChange'
        :bannerOptions="bannerOptions"
      ></main-autonotices-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule='slotProps.rule'
        :sourceSuggestions='sourceSuggestions'
        :categoryOptions='categoryOptions'
        @rule-change='slotProps.handleRuleChange'
        @search-suggestions="searchSuggestions"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
  import Vue from 'vue';
  import { Form, FormItem, Input, Button } from 'element-ui';
  import ServicePolicyEdit from '@/components/ServicePolicies/ServicePolicyEdit';
  import MainCard from './components/MainCard';
  import RuleConfigCard from './components/RuleConfigCard';
  import { mapGetters, mapActions }			 from "vuex";

  export default {
    name: 'autonotice-edit',
    components: {
      'el-form': Form,
      'el-input': Input,
      'el-button': Button,
      'el-form-item': FormItem,
      'main-autonotices-config-card': MainCard,
      'rule-config-card': RuleConfigCard,
      'service-policy-edit': ServicePolicyEdit
    },
    props: [ 'ruleId'],
    data() {
      return {
        rule: {
          rule_name: '',
          status: false,
          allowed_forbidden: 'none',
          action: { type: 'redirect' },
          log_activity: true,
          source: [],
          category: [],
          frequency: {display: { when: 'always'}},
          defined_urls: [],
          schedule: {
            weekdays: {
              monday: false, tuesday: false, wednesday: false,
              thursday: false, friday: false, saturday: false, sunday: false
            },
            startTimeSlot: '',
            endTimeSlot: '',
            startDateSlot: '',
            endDateSlot: ''
          }
        },
        sourceSuggestions: [],
        autonoticesPackages: [],
        bannerOptions: { redirections: [], banners: [], smartbanners: []},
      }
    },
    computed: {
      ...mapGetters('networksecure/autoNotices', ['getConfigurationNames']),
      ...mapGetters('settings',['getLanguage']),
      ...mapGetters('ispServices/categoryManagement', ['categories']),
      categoryOptions(){
        let cats = this.categories
        if ( !cats)
          return [];

        return cats.map( (item => ({name: item.isp_internal_category_id, value: this.t(item.isp_internal_category_id)})).bind(this));
        // TO USE ISP CATEGORIES, uncomment this
        // return cats.map ( (item => {
        //   if ( !item.isp_category_i18n)
        //     return { name: item.isp_category_id, value: item.isp_category_id}
        //   let translation = item.isp_category_i18n.filter( lang => lang.language_id.startsWith(this.getLanguage))
        //   if ( !translation || translation.length == 0)
        //     translation = item.isp_category_i18n.filter( lang => lang.language_id.startsWith('en'))
        //   if ( !translation || translation.length == 0)
        //     return { name: item.isp_category_id, value: item.isp_category_id}
        //   else
        //     return { name: item.isp_category_id, value: translation[0].isp_category_name}
        // }).bind(this));
      }
    },
    methods: {
      ...mapActions('networksecure/autoNotices', ['getAutoNoticesAdvancedConfiguration', 'getAutoNoticesPackages']),
      ...mapActions("ispServices/categoryManagement", ["getCategories"]),
      ...mapGetters("networksecure", ["getServicesModules"]),
      actionValidator(rule, value, callback) {
        let error = null
        switch(value.type){
          case 'allow':
            break;
          case 'redirect':
          case 'banner':
          case 'smartbanner':
            if (!!!value.value)
              error = 'please select a list';
            break;
          default:
            error = 'please select a list';
        }
        if (error)
          callback(new Error(this.t(error)));
        else
          callback();
      },
      async searchSuggestions(data) {
        const queryString = data.queryString;
        const devicesQuery = {
          field_name: 'ns_id',
          field_operator: "sw",
          field_value: queryString,
          sort: 'asc'
        };
        let sourceSuggestions = [...this.autonoticesPackages.filter( (item) => {
            return (item.value.toLowerCase().startsWith(queryString.toLowerCase()));
          })
        ];
        this.sourceSuggestions = sourceSuggestions
            .sort((a, b) => (a.value > b.value) ? 1 : -1)
            .slice(0, 10);

        let _vm = this
        this.$nextTick( () => {
          this.$store.dispatch('accountManagement/devices/getDevices', {query: devicesQuery})
          .then ( res => {
            const devicesMap = new Map(res.items);
            for (const [key, value] of devicesMap.entries()){
              const deivceNSid = _.get(value, "product_data.NetworkSecure.ns_id")
              if(deivceNSid && deivceNSid !== "-"){
                sourceSuggestions.push({value: deivceNSid, type: 'device'})
              }
            }
          })
          .catch( err => {})
          .finally( () => {
            _vm.sourceSuggestions = sourceSuggestions
              .sort((a, b) => (a.value > b.value) ? 1 : -1)
              .slice(0, 10);
            data.done();
          })
        });
      },
    },
    async created() {
      Promise.all(['redirections', 'banners', 'smartbanners'].map (type => (this.getAutoNoticesAdvancedConfiguration({type: type}))))
      .then( res =>{
        this.bannerOptions = this.getConfigurationNames;
      })
      .catch(err => {
        let path = this.$route.fullPath.split('/')
        path = path.splice(0,path.length-2)
        this.$router.replace({path: path.join('/')})
      })

      this.getCategories();
      this.getAutoNoticesPackages()
      .then( res => {
        this.autonoticesPackages = res.map( item => ({value: item, type: 'autonotice_package'}))
      })
      .catch()
      .finally( () => {
        this.searchSuggestions({ queryString:'', done: () => {} })
      })
    },
  }
</script>