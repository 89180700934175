<template>
  <card class="adsfree__rule-config-card padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            Add one or more <span class="accounts-word">accounts</span>, <span class="mac-word">mac</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> or <span class="ipv6-word">{{this.t('ipv6')}}</span> with or without mask. Leave empty for "Any"
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'mac', 'account']"
            :inputType="'Source'"
            :value='rule.source'
            :options='accounts'
            @change="handleChange($event, 'source')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('destination')" class="label-in-top">
          <p>
            Add one or more <span class="accounts-word">accounts</span>, <span class="mac-word">mac</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> or <span class="ipv6-word">{{this.t('ipv6')}}</span> with or without mask. Leave empty for "Any"
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'mac', 'account']"
            :inputType="'Destination'"
            :value='rule.destination'
            :options='accounts'
            @change="handleChange($event, 'destination')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('Category')" class="label-in-top">
          <p>
            Add one or more categories such as social networks, violence... Leave empty for "Any" 
          </p>
          <autocomplete-tag-input
            :value='rule.category'
            :options='categoryOptions'
            @change="handleChange($event, 'category')"
          ></autocomplete-tag-input>
        </el-form-item> 
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('Urls')" class="label-in-top">
          <p>
            Add one or more URLs (or domains). If the URL has to be excluded type 
            <span class="not-url">"not:"</span> and the URL 
          </p>
          <autocomplete-tag-input
            inputType='url'
            :value='rule.defined_urls'
            @change="handleChange($event, 'defined_urls')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
  </card>
</template>

<script>
  import { Input, FormItem, Select, Checkbox } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  import _ from 'lodash';
  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'el-select': Select,
      'el-checkbox': Checkbox,
      'el-form-item': FormItem,
      'autocomplete-tag-input': AutocompleteTagInput
    },
    props: {
      rule: {
        type: Object,
        default: () => {}
      },
      accounts: {
        type: Array,
        default: () => []
      },
      categoryOptions: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mac-word {
    color: #51a427;
  }
  .accounts-word {
    color: #2c6fb4;
  }
  .ipv4-word {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
</style>

<style lang="scss">
  .adsfree__rule-config-card {
    .rule-protocol-select { width: 100%; }
    @media (max-width: 1700px) {
      .main-label-position {
        label {
          width: auto!important;
          float: none;
          display: inline-block;
          text-align: left;
          padding: 0;
          margin: 0;
        }
        div { margin-left: 0!important; }
      }
    }
  }
</style>
