<template>
  <card class="contentfilter__main-card padding-1">
    <h6 class="policy-card-title">
        <svgicon class="icon" icon="rule-actions" width="16" height="16" />
        <span style="padding-left:1em">{{t('rule actions')}}</span>
    </h6>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('status')">
        <div class="item-alignment">
          <n-switch
            v-model='status'
            @input="handleChange($event, 'status')"
            color="allot"
          ></n-switch>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('action')" prop='action'>
        <div class="item-alignment">
          <el-select
            :value="actionValue"
            placeholder="Select an action"
            @input="handleChange($event, 'type')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in actionOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled">
            </el-option>
          </el-select>
        </div>
        <div class="item-alignment">
          <el-input
            :value='rule.action.value'
            class="input-block-with-url-width"
            @input="handleChange($event, 'value')"
            v-if="rule.action.type === 'blockWithThisURL'"
            :placeholder="t('block with this URL')"
          ></el-input>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="140px" :label="t('log activity')">
        <div class="item-alignment">
          <el-checkbox
            :value='rule.log_activity'
            @input="handleChange($event, 'log_activity')"
            placeholder='Rule name'
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
  </card>
</template>

<script>
  import { FormItem, Checkbox, Select, Option, InputNumber } from 'element-ui';
  import Switch from '@/components/Switch';
  export default {
    name: 'main-contentFilter-config-card',
    components: {
      'el-checkbox': Checkbox,
      'el-form-item': FormItem,
      'n-switch': Switch,
      'el-select': Select,
      'el-option': Option,
      'el-input-number': InputNumber
    },
    mounted(){
      if(!_.get(this.rule, "action.type")){
        this.handleChange("allow", 'type')
      }
      if(this.rule.action.type === "block_with_url"){
        this.rule.action.type = "blockWithThisURL"
      }
    },
    data() {
      return {
        sslActions: [
          'ssl_termination',
          'bypass_ssl_termination'
        ]
      }
    },
    props: {
      rule: {
        type: Object,
        default: () => {
          return {
            log_activity: true,
            status: false,
            action: {type: ''},
          }
        }
      },
      actionOptions: {
        type: Array,
        default: () => []
      },
      sslActionsValid: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      status: {
        get() { return this.rule.status },
        set(newRuleStatus) {
          let updatedRule = JSON.parse(JSON.stringify(this.rule));
          updatedRule.status = newRuleStatus;
          this.$emit('rule-change', updatedRule);
        }
      },
      actionValue() {
        if (!this.sslActionsValid && this.sslActions.includes(this.rule.action.type)) {
          this.rule.action.type = 'allow';
        }
        return this.rule.action.type;
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = JSON.parse(JSON.stringify(this.rule));
        if (input === 'type') {
          updatedRule.action[input] = event;
          updatedRule.action.value = undefined;
        } else if (input === 'value') updatedRule.action.value = event;
        else updatedRule[input] = event;

        this.$emit('rule-change', updatedRule);
      }
    }
  }
</script>

<style lang='scss'>
  .contentfilter__main-card.card {
    font-family: "AmpleSoft Pro Medium", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    border-radius: 0.5em;
    .card-body { margin-top: 1em; }
    .form-item { width: 70%; }
  }
</style>
