<template>
  <card class="antivirus__main-card padding-1">
    <h6 class="policy-card-title">
        <svgicon class="icon" icon="rule-actions" width="16" height="16" />
        <span style="padding-left:1em">{{t('rule actions')}}</span>
    </h6>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('status')">
        <div class="item-alignment">
          <n-switch
            v-model='status'
            @input="handleChange($event, 'status')"
            color="allot"
          ></n-switch>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('action')" prop='action'>
        <div class="item-alignment">
          <el-select
            :value="rule.action.type"
            placeholder="Select an action"
            @input="handleChange($event, 'type')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in actionOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="140px" :label="t('log activity')">
        <div class="item-alignment">
          <el-checkbox
            :value='rule.log_activity'
            @input="handleChange($event, 'log_activity')"
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
  </card>
</template>

<script>
  import { FormItem, Checkbox, Select, Option, InputNumber } from 'element-ui';
  import Switch from '@/components/Switch';
  import _ from 'lodash';
  export default {
    name: 'main-anti-virus-config-card',
    components: {
      'el-checkbox': Checkbox,
      'el-form-item': FormItem,
      'n-switch': Switch,
      'el-select': Select,
      'el-option': Option,
      'el-input-number': InputNumber
    },
    props: {
      rule: {
        type: Object,
        default: () => {
          return {
            log_activity: true,
            status: false,
            action: {type: ''},
            event: ""
          }
        }
      },
      logEventOptions: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      status: {
        get() { return this.rule.status },
        set(newRuleStatus) {
          let updatedRule = _.cloneDeep(this.rule);
          updatedRule.status = newRuleStatus;
          this.$emit('rule-change', updatedRule);
        }
      },
      actionOptions: {
        get() {
          let options = [{value: '', label: this.t('select an action'), disabled: true}];
          if (!this.rule.hasOwnProperty('protocol') || this.rule.protocol.toLowerCase() === 'web') {
            options.push(
              {value: 'allow', label: this.t('allow')},
              {value: 'block', label: this.t('block')}
            );
          } else if (this.rule.protocol.toLowerCase() === 'mail') {
            options.push(
              {value: 'delete', label: this.t('delete')},
              {value: 'allow', label: this.t('allow')},
              {value: 'quarantine', label: this.t('quarantine')},
              {value: 'tagSubject', label: this.t('tag subject')}
            );
          }
          return options;
        }
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        if (input === 'type') updatedRule.action[input] = event;
        else if (input === 'event') updatedRule.event.status = event;
        else updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
      }
    },
    watch: {
      'rule.protocol' (newProtocol, oldProtocol) {
        if (oldProtocol !== '' && newProtocol !== oldProtocol) {
          this.rule.action = {type: 'allow', value: undefined};
        }
      }
    }
  }
</script>

<style lang='scss'>
  .antivirus__main-card.card {
    font-family: "AmpleSoft Pro Medium", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    border-radius: 0.5em;
    .card-body { margin-top: 1em; }
    .form-item { width: 70%; }
  }
</style>
