<template>
  <div>
      <el-form
        :model="configuration"
        ref="autonotice-adv-conf-form"
        label-width="120px"
        label-position="left"
        :rules="formRules"
      >

      <b-container id="page_AutonoticeAdvancedConfigurationEdit" fuild style="max-width: 2560px;" class="p-0">
        <b-row>
          <b-col>
        <b-card no-body> 
          <page-head-actions 
                  :title="id" :editable="!isEdited"
                  :isRealId="isEdited" :updating="updating" @refresh="update"
                  :placeholder="t('list_name')"
                  v-model="name" name="id" v-validate="validations.name" :error="errors.first('id')">
            <template slot="buttons">
              <n-button type="primary" size="md" round block @click.native="handleSave" :visible_for_permissions="visible_for_permissions">{{t('save')}}</n-button>
              <n-button type="primary" size="md" round block @click.native="handleBack">{{t('back')}}</n-button>
            </template>
          </page-head-actions>

        </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card class="padding-1">
              <h5 class="advanced-header"><i class="tim-icons icon-link-72 mr-1"/>{{t('Type of redirection')}}
              </h5>

              <b-container fluid class="mt-4" style="padding: 0px;" >
                <b-row no-gutters>
                  <b-col sm="10" md="8" lg="6" xl="5" class="item-alignment">
                    <label class="mr-1" style="white-space: nowrap;">{{ t('Type of redirection')}}</label>
                    <el-select v-model="mode" class="rule-action-select select-primary">
                      <el-option
                        v-for="option in modeOptions"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                        :disabled="option.disabled"
                      ></el-option>
                    </el-select>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>

          <b-col md="6" lg="6" xl="5" class="row-eq-height">
            <b-card class="padding-1">
              <h5 class="advanced-header"> 
                  <svgicon :icon="iconadd" class="mr-1" width="16" height="16"/> 
                  <span> {{t(titleadd)}}</span>  
              </h5>

              <b-container fluid style="padding: 0px;" >
                <b-row no-gutters class="justify-content-around">
                  <b-col cols="12" v-if="type === 'smartbanners'" >
                    <el-form-item :label="t('Type:')" label-width="70px" prop="bannerType">
                      <el-select v-model="bannerType" class="banner-option-select select-primary">
                        <el-option
                          v-for="option in bannerTypeOptions"
                          :key="option.value"
                          :label="t(option.label)"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </b-col>

                  <b-col cols="8">
                    <el-form-item class="an-adv-conf-form-group" prop="url" label-width="0px">
                      <el-input
                        v-model="configuration.url"
                        :placeholder="t('Enter a valid url *')"
                      ></el-input>
                    </el-form-item>
                  </b-col>
                  <b-col cols="3"> 
                    <n-button class="add-url-button" round type="primary" @click.native='addNewUrl' :visible_for_permissions="visible_for_permissions">{{t('Add')}}</n-button>
                  </b-col>
                </b-row>
                
                <b-row no-gutters v-if="type === 'banners'">
                  <b-col>
                      <autonotice-banner :position="position"/>
                  </b-col>
                </b-row>

              </b-container>
            </b-card>
          </b-col>

          <b-col md="6" lg="6" xl="7" class="row-eq-height" style="display: block;">
            <b-card class="padding-1">
              <h5 class="advanced-header"> 
                  <svgicon icon="filtering-log-configuration" class="mr-1" width="16" height="16"/> 
                  <span> {{t(type + ' configuration')}}</span>  
              </h5>

              <b-container fluid style="padding: 0px;" >
                <b-row no-gutters>
                  <b-col>
                    <paginated-table
                      :rows="rows"
                      :columns="columns"
                      :actions="actions"
                      @item-delete='handleDelete'
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-card>

          </b-col>
        </b-row>
      </b-container>
      </el-form>

      <b-modal id="modal" ref="modal" :ok-title="t('accept')" :cancel-title="t('cancel')"
        @ok="backConfirm" @cancel="backCancel" :title="t('go_back_title')">
        {{ t('go_back_content') }}
      </b-modal>

  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import { PaginatedTable } from "@/components";
import Banner from './Banner.vue';
import PageHeadActions from '@/components/PageHeadActions.vue';
import {
  urlWithProtocolDomainHostRegExp,
  urlWithProtocolIpv4HostRegExp,
  urlWithProtocolIpv6HostRegExp
} from "@/util/regexConsts";

export default {
  name: "autonotices-advanced-configuration-edit",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    PaginatedTable,
    'autonotice-banner': Banner,
    PageHeadActions
  },
  props: ["type", "id", "visible_for_permissions"],
  data() {
    return {
      updating: false,
      url: '',
      source: '',
      banner: '',
      errorMessages: {
        name: '',
        url: ''
      },
      validations: {
        name: { required: true,
          max: 30,
          regex: /^[a-zA-Z_]+[\w-]+$/
         },
      },

      name: null,
      mode: 'random',
      items: [],

      modeOptions: [{value: 'random', label: this.t('Randomly')}, 
            {value: 'random_no_repeat', label: this.t('Randomly without repetition')}, 
            {value: 'sequential', label: this.t('Sequentially')}
      ],
      actions: {
        minWidth: 65,
        label: this.t('Actions'),
        fixed: "right",
        items: [
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            action: this.t('Delete'),
            confirm: true,
            event: "item-delete",
            message: "Are you sure you want to delete this item?", 
            visible_for_permissions: this.visible_for_permissions
          }
        ]
      },
      bannerType: 'icon',
      bannerTypeOptions: [{value: 'icon', label: "Icon"}],
      position: {
        type: "left",
        width: {
          number: 20,
          unit: "percentage"
        },
        height: {
          number: 20,
          unit: "percentage"
        }
      },
      configuration: null,
      urlRegExp: new RegExp(`(${urlWithProtocolDomainHostRegExp.source})|(${urlWithProtocolIpv4HostRegExp.source})|(${urlWithProtocolIpv6HostRegExp.source})`),
    }
  },
  methods: {
    resetConfiguration(){
      this.configuration = {
        url: '',
        position: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        size: {
          width: {
            number: 20,
            unit: '%',
          },
          height: {
            number: 20,
            unit: '%'
          },
        }
      };
    },
    handleDelete(data) {
     this.items.splice(data.index, 1);
    },
    validateUrl(field, newItem) {
      if (this.urlRegExp.test(newItem)) {
        return true
      } else {
        this.errorMessages[field] = this.t('valid-url');
        return false
      }
    },
    validateSize(field, newItem) {
      if (newItem > 0) return true;
      else return false;
    },
    resetErrors() {
      for (const error in this.errorMessages) {
        if (this.errorMessages.hasOwnProperty(error)) {
          this.errorMessages[error] = '';
        }
      }
    },
    addNewUrl(info) {
      this.resetErrors();
      switch(this.type){
        case 'banners':
          if (this.validateUrl('url', this.configuration.url) && this.validateSize('bannerSize', this.position.width.number)) {
            this.items.push({
                  url: this.configuration.url, 
                  position: this.position.type, 
                  width: parseInt(this.position.width.number), 
                  unit: this.position.width.unit
            })       
          }
          break;
        case 'redirections':
          if (this.validateUrl('url', this.configuration.url) ){
            this.items.push(this.configuration.url)       
          }
          break;
        case 'smartbanners':
          if (this.validateUrl('url', this.configuration.url) ){
            this.items.push({
                  show: this.configuration.url,
                  SmartBannerType: 'SmartBannerIcon'
            })       
          }
          break;
      }

      this.resetConfiguration()

    },
    async handleSave() {
      const isValid = await this.$validator.validate();
      if (!isValid) 
        return;

      let data = {
        data: {
          autonotice_list_id: this.isEdited ? this.id : this.name,
          mode: this.mode,
          items: this.items
        },
        type: this.type
      }

      if ( this.isEdited)
          this.$store.dispatch('networksecure/autoNotices/UpdateConfiguration', data)
          .then( res => {
            this.$store.commit('setSuccess', this.t('save_success'));
          })
          .catch( err => {
          })
      else
          this.$store.dispatch('networksecure/autoNotices/CreateConfiguration', data)
          .then( res => {
            this.$store.commit('setSuccess', this.t('save_success'));
            let routename = 'Network Secure / AutoNotices / Advanced Configuration / '
            switch(res.type){
              case 'banners':
                routename = routename + 'Banners Insertion / Edit'
                break;
              case 'redirections':
                routename = routename + 'Redirection / Edit'
                break;
              case 'smartbanners':
                routename = routename + 'Smart Banners / Edit'
                break;
            }
            this.$router.replace({ name: routename, params : { type: res.type, id: res.data.autonotice_list_id}})
          })
          .catch( err => {})
    },
    handleBack() {
      this.$refs.modal.show();
    },
    backConfirm(){
      this.$router.back();
    },
    backCancel(){
      this.$refs.modal.hide();
    },
    async update() {
      try {
        this.updating = true
        this.$store.dispatch('networksecure/autoNotices/getConfigurationByName', { type: this.type, id: this.id})
        .then( res => {
          console.log(res)
          this.mode = res.mode
          this.items = res.items
          this.updating = false
        })
        .catch( err => {
          this.updating = false
        })
      } catch(e) {
        console.error(`Error getting autonotice rule ${this.data}--> `, e);
      }
    },
  },
  computed: {
    isEdited(){
        return !!this.id
    },
    iconadd() {
        if ( this.type === 'smartbanners')
          return 'filtering-log-configuration'
        else
          return 'alerts-list'
    },
    titleadd(){
        if ( this.type === 'smartbanners')
          return 'Banner Type'
        else
          return 'Add Urls'
    },
    rows(){
      switch(this.type){
        case 'redirections':
          return this.items.map (element => ({
                  url: element
          }));
        case 'banners':
          return this.items.map (element => ({
                  position: element.position, 
                  url: element.url, 
                  size: element.width + (element.unit == 'pixels' ? 'px' : '%')
          }));
        case 'smartbanners':
          return this.items.map (element => ({
                  url: element.show, 
                  type: this.t(element.SmartBannerType)
          }));
        default:
          return this.items;
      }
    },
    columns(){
      switch(this.type){
        case 'banners':
          return [
            { prop: "url", label: this.t('Url'), minWidth: 130 },
            { prop: 'position', label: this.t('position'), minWidth: 100 },
            { prop: 'size', label: this.t('size'), minWidth: 60 }
          ];
        case 'redirections':
          return [
            { prop: "url", label: this.t('Url'), minWidth: 130 }
          ];
        case 'smartbanners':
          return [
            { prop: "url", label: this.t('Url'), minWidth: 200 },
            { prop: "type", label: this.t('type'), minWidth: 70 },
          ];
      }
    },
    formRules() {
      return {
        url: [
          {
            type: "string",
            pattern: this.urlRegExp,
            message: this.t('valid-url'),
            trigger: ['blur', 'change']
          }
        ],
      }
    },
  },
  created() {
    this.resetConfiguration()
    if (this.id){
      this.name =  this.id
      this.update();
    }
  },
};
</script>
<style scoped lang="scss">
  .advanced-header {
    margin-left: 1em;

    span {
      margin-left: 1em;
    }
  }

  .rule-save-button {
    margin-top: 0px;
  }

  .add-url-button {
    margin-top: 0px;
    padding-right: 2em;
    padding-left: 2em;
    min-width: 5em;
  }

  div.card-body {
    padding: 0px;
  }

  div.card.page-head-actions {
    margin-bottom: 0px;
  }

  div.card-footer {
    padding-top: 0px;
  }

  .banner-option-select {
    margin-top: -0.8em;
    width: 70%;
    max-width: 26.4em;
  }
</style>
