<template>
  <b-card class="allowed-blocked-table">
    <div v-if="addable" class="col-12">
      <div v-if="actionText !== ''" class="row">
        <span class="action-text">{{actionText}}</span>
      </div>
      <div class="row list-input-container">
        <div class="col-9">
          <el-input
            clearable
            :placeholder="inputPlaceholder"
            v-model="newItem"
            @change="errorMessage = undefined"
          />
        </div>
        <div class="col-3">
          <el-button :disabled="newItem==''" class="btn  btn-primary" type="primary" @click="addItem">{{t(buttonText)}}</el-button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <p v-if='errorMessage' class="errorMessage">{{t(errorMessage)}}</p>
    </div>
    <div v-if="searchable" class="col-12 search-box">
      <div class="col-6">
        <el-input
          type="search"
          clearable
          prefix-icon="el-icon-search"
          :placeholder="t('type-for-search')"
          v-model="searchField"
        />
      </div>
    </div>
    <div class="col-12 list-items-container">
      <div v-if="searchedList.length > 0" class="col-12">
        <div class="row row-bottom-border" v-for="i in Math.ceil(currentPageItems.length / 2)" :key="i">
          <div v-for="(item) in currentPageItems.slice((i - 1) * 2, i * 2)" :key="item" class="col-6 list-item-container">
            <div :class="editable ? 'col-8' : 'col-10'">
              {{item.toUpperCase()}}
            </div>
            <div :class="editable ? 'tr-buttons col-4' : 'col-2'">
              <el-tooltip v-if="editable" class="tr-edit-button" :content="t('edit')" :open-delay="250" key="item-edit">
                <n-button
                  @click.native="() => editItem(item)"
                  type="warning"
                  size='sm' round icon
                >
                  <i class="now-ui-icons business_badge"></i>
                </n-button>
              </el-tooltip>
              <el-tooltip :content="t('delete')" :open-delay="250" key="item-delete">
                <n-button
                  @click.native="() => deleteItem(item)"
                  type="danger"
                  size='sm' round icon
                >
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>

      <template v-else>
          <empty-state/>
      </template>
    </div>
    <div class="col-12 list-footer-container">
      <div v-if="searchedList.length > 0" slot="footer" class="col-12 paginated-table-card__pagination-wrapper">
          <div class="paginated-table-card__pagination-item mb-4 mb-sm-0">
            <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>

          <div class="paginated-table-card__pagination-item order-3 order-sm-1">
            <span class="card-category pr-2">Results per page:</span>
            <el-select
                class="select-primary paginated-table-card__perpage-select"
                v-model="perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
            </el-select>
          </div>
        <n-pagination
          class="pagination-no-border mb-0"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="searchedList.length"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import Pagination from '../Pagination';
import EmptyState from '../EmptyState';
import { Input, Button, Select, Option } from 'element-ui';
import _ from 'lodash';

  export default {
    name: 'AllowedBlockedList',
    components: {
      'n-pagination' : Pagination,
      'empty-state' : EmptyState,
      'el-input' : Input,
      'el-button': Button,
      'el-select': Select,
      'el-option': Option
    },
    data() {
      return {
        newItem: '',
        searchField: '',
        pagination: {
          currentPage: 1,
          perPage: 6,
          perPageOptions: [2, 6, 8, 12, 16, 20]
        },
        errorMessage: undefined
      }
    },
    props: {
      inputPlaceholder: {
        type: String,
        default: 'Type an element to add'
      },
      inputRegEx: {
        type: String,
        default: undefined
      },
      listContent: {
        type: Array,
        default: () => []
      },
      actionText: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: 'Add'
      },
      validationErrorMessage: {
        type: String,
        default: 'Type a valid option'
      },
      searchable: {
        type: Boolean,
        default: false
      },
      addable: {
        type: Boolean,
        default: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedList.length;
      },
      perPage: {
        get() {
          return this.pagination.perPage
        },
        set(newValue){
          this.pagination.perPage = newValue
          this.$emit('pagination', newValue)
        }
      },
      currentPageItems: {
        get() {
          return  this.searchedList.slice((this.from), (this.to));
        }
      },
      searchedList: {
        get() {
          return this.searchField === ''
            ? this.listContent
            : this.listContent.filter(item => item.includes(this.searchField))
        }
      }
    },
    methods: {
      addItem() {
        this.errorMessage = undefined;
        let contentCopy = _.cloneDeep(this.listContent);
        const validationRegex = new RegExp(this.inputRegEx);
        if (validationRegex.test(this.newItem)) {
          contentCopy.push(this.newItem);
          this.newItem = '';
          this.$emit('item-added', contentCopy);
        } else {
          this.errorMessage = this.validationErrorMessage;
        }
      },
      deleteItem(item) {
        this.errorMessage = undefined;
        let contentCopy = _.cloneDeep(this.listContent);
        contentCopy = contentCopy.filter((a) => a !== item);
        this.$emit('item-deleted', contentCopy);
      },
      editItem(item) {
        this.$emit('item-edit', item);
      }
    }
  }
</script>

<style lang="scss">
  .allowed-blocked-table {
    .list-input-container {
      display: flex;
      align-items: center;
    }
    .search-box {
      margin-top: 2em;
    }
    .list-items-container {
      margin-top: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .list-item-container  {
      display: flex;
    }
    .row-bottom-border {
      border-bottom: solid 1px #e3e3e3;
    }
    .list-footer-container {
      margin-top: 3%;
    }
    .tr-buttons {
      display: flex;
      justify-content: flex-end;

      .tr-edit-button {
        margin-right: 0.5em;
      }
    }
    .action-text {
      margin-left: 1em;
    }
  }
</style>
