<template>
  <div class="tags-container">
    <template v-if="Array.isArray(row[column.prop])">
      <template v-if="row[column.prop].length === 0">
        <el-tag class="default-tag">{{t('any')}}</el-tag>
      </template>
      <template v-for="(tag, index) in row[column.prop]">
        <el-tag
          :class="requestTag(tag)"
          :key="tag.hasOwnProperty('value') ? tag.value : tag" v-if="index < 4">
          {{ tag.hasOwnProperty('value') ? tag.value : tag }}
        </el-tag>
      </template>
      <template v-if="row[column.prop].length > 4">
        <br>
        <el-tooltip
          class="item" effect="dark" placement="top"
          :content="row[column.prop].map(tag => tag.hasOwnProperty('value') ? tag.value : tag).join(', ')"
        >
          <el-button class="more-tags">...</el-button>
        </el-tooltip>
      </template>
    </template>
    <el-tag class="default-tag" v-else>
      {{row[column.prop]}}
    </el-tag>
  </div>
</template>

<script>
  import { Tag } from "element-ui";

  export default {
    name: 'urls-formatter',
    components: {
      'el-tag': Tag
    },
    props: {
      row: {
        type: Object,
        default: () => {}
      },
      column: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {

      }
    },
    methods: {
      requestTag (tag) {
        if (tag.includes('not:')) {
          return 'deny-tag'
        }
        if ( tag.includes('Any')) {
          return 'default-tag'
        }
        return 'allow-tag'
      }
    }
  }
</script>

<style scoped lang=scss>
  .more-tags {
    padding: 0.2em 0.5em;
  }
  .tags-container {
    text-align: center;
  }
  .default-tag {
    background-color: #cfcfcf;
    border-color: #a7a7a8;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }

  .deny-tag {
    background-color: #f0d9d9;
    border-color: #d38181;
    color: #c15050;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .allow-tag {
    background-color: #dceed2;
    border-color: #a1c191;
    color: #51a427;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
</style>