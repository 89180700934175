<template>
  <div>
    <page-title-card
      :page-title="t('advanced-configuration')"
    >
      <template #button>
        <n-button
          type="primary"
          @click.native="submitForm('form')"
          class="btn btn-round btn-primary"
          style="width: -webkit-fill-available"
          :visible_for_permissions="[
              {
                actions: ['PATCH'],
                path: '/subsystems/ns/service-configuration',
              },
            ]"
        >
          {{ t("save") }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body class="antiphishing__advanced">
      <el-form
        ref="form"
        :model="advancedConfiguration"
        :rules="formRules"
        label-width="140px"
        label-position="top"
        style="padding-left: 30px; padding-right: 30px; padding-top: 30px"
      >
        <div class="row">
          <div class="col-12">
            <el-form-item
              :label="t('custom_blocking_page_url')"
              prop="custom_blocking_page_url"
            >
              <div class="page-mode-div">
                <el-select
                  v-model="advancedConfiguration.page_mode"
                  class="page-mode-select"
                >
                  <el-option
                    v-for="option in modeOptions"
                    :key="option.value"
                    :label="t(option.label)"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="advancedConfiguration.page_mode === 'custom'"
                  v-model="advancedConfiguration.custom_blocking_page_url"
                  class="form-group no-height form-control-lg input-group page-mode-input"
                ></el-input>
                <el-input
                  v-else
                  disabled
                  :value="`${default_blocking_page_url.name} - ${default_blocking_page_url.blocking_page_url}`"
                  class="form-group no-height form-control-lg input-group page-mode-input"
                ></el-input>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <el-form-item
              :label="t('disabled_phishing_inspection_categories')"
              prop="disabled_phishing_inspection_categories"
            >
              <autocomplete-tag-input
                :value="currentCategoryOptions"
                :options="categoryOptions"
                :placeholder="t('disabled_phishing_inspection_categories')"
                @change="handleCategoriesChange($event)"
                :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/service-configuration' }]"
              ></autocomplete-tag-input>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <b-modal
        id="modal-new"
        ref="modal"
        :title="t('advanced-configuration')"
        @ok="getData"
      >
        <div>{{ t("confirmation-text") }}</div>
      </b-modal>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="cancel"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("update-now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("updating...") }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import _ from "lodash";
import AutocompleteTagInput from "@/components/Inputs/AutocompleteTagInput.vue";
import { mapGetters, mapActions } from "vuex";
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "anti-phishing-advanced-configuration",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    "autocomplete-tag-input": AutocompleteTagInput,
    PageTitleCard
  },
  props: [""],
  data() {
    return {
      advancedConfiguration: {
        page_mode: "default",
        custom_blocking_page_url: "",
        disabled_phishing_inspection_categories: [],
      },
      default_blocking_page_url: {
        name: "",
        blocking_page_url: "",
      },
      updating: false,
      formRules: {
        custom_blocking_page_url: [
          {
            type: "url",
            message: this.t("url_field"),
            trigger: ["blur", "change"],
          },
        ],
      },
      modeOptions: [
        { value: "default", label: "default" },
        { value: "custom", label: "custom" },
      ],
      currentCategoryOptions: [],
    };
  },
  computed: {
    ...mapGetters("ispServices/categoryManagement", ["categories"]),
    categoryOptions() {
      let cats = this.categories;
      if (!cats) return [];

      return cats.map(
        ((item) => ({
          option: item.isp_internal_category_id,
          value: this.t(item.isp_internal_category_id),
        })).bind(this)
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("ispServices/categoryManagement", ["getCategories"]),
    async getData() {
      try {
        this.updating = true;

        await this.getCategories();

        await this.$store.dispatch(
          "networksecure/antiPhishing/getAdvancedConfiguration"
        );
        let config = _.cloneDeep(
          this.$store.getters[
            "networksecure/antiPhishing/getAdvancedConfiguration"
          ]
        );
        this.advancedConfiguration = {
          page_mode: config.blocking_page.page_mode,
          custom_blocking_page_url:
            config.blocking_page.custom_blocking_page_url,
          disabled_phishing_inspection_categories:
            config.disabled_phishing_inspection_categories,
        };
        this.currentCategoryOptions = this.categoryOptions.filter(
          option => config.disabled_phishing_inspection_categories.includes(option.option)
        );
        await this.$store.dispatch(
          "networksecure/antiPhishing/getDefaultBlockingUrl"
        );
        this.default_blocking_page_url = this.$store.getters[
          "networksecure/antiPhishing/getDefaultBlockingUrl"
        ];

        this.updating = false;
      } catch (e) {
        console.error(
          "Error getting anti phishing advanced configuration data--> ",
          e
        );
        this.updating = false;
      }
    },
    handleCategoriesChange(value) {
      const tempValue = value.map(element => element.value ? element.value : element);
      const selected = this.categoryOptions.filter(option => tempValue.includes(option.value));
      const selectedValues = selected.map(element => element.option ? element.option : element);
      this.currentCategoryOptions = selected;
      const disabled_phishing_inspection_categories = selectedValues;
      this.advancedConfiguration = {
        ...this.advancedConfiguration,
        disabled_phishing_inspection_categories,
      };
    },
    submitForm(formName) {
      this.updating = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.$store.dispatch(
            "networksecure/antiPhishing/updateAntiPhishingAdvancedConfiguration",
            this.advancedConfiguration
          );
          this.updating = false;
        } else {
          console.error("Validation error");
          this.updating = false;
          return false;
        }
      });
    },
    cancel() {
      this.$refs.modal.show();
    },
  },
};
</script>
<style lang="scss">
.antiphishing__advanced {
  .centered-reset-button {
    display: flex;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 30px;

    button {
      max-width: 20em;
    }
  }
  @media (min-width: 600px) {
    .page-mode-div {
      display: flex;
      align-items: baseline;
      padding: 5px;
    }
    .page-mode-select,
    .page-mode-input {
      margin: 5px;
    }
  }
  .page-mode-select .el-input .el-input__inner {
    background: #f57448!important;
  }
}
</style>
