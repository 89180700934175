<template>
  <card class="padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            {{t('Add one or more')}} <span class="devices-word">{{this.t('devices')}}</span>, <span class="mac-word">{{t('mac')}}</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> {{t('or')}} <span class="ipv6-word">{{this.t('ipv6')}}</span> {{this.t("with or without mask. Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'mac', 'device']"
            :inputType="'Source'"
            :placeholder="t('source')"
            :value='rule.source'
            :options='devicesAndServices'
            @change="handleChange($event, 'source')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('destination')" class="label-in-top">
          <p>
            {{t('Add one or more')}}  <span class="devices-word">{{this.t('devices')}}</span>, <span class="mac-word">{{t('mac')}}</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> {{t('or')}} <span class="ipv6-word">{{this.t('ipv6')}}</span> {{this.t("with or without mask. Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'mac', 'device']"
            :inputType="'Destination'"
            :placeholder="t('destination')"
            :value='rule.destination'
            :options='devicesAndServices'
            @change="handleChange($event, 'destination')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('application protocol')" class="label-in-top">
          <p>{{t("Add one or more application protocol such as http, https, dns, mqtt... Leave empty for 'Any'")}}</p>
          <autocomplete-tag-input
            :inputType="'Application protocol'"
            :value='rule.protocol'
            :options='applicationProtocolOptions'
            :placeholder="t('application protocol')"
            :disabled="!isHostsEmpty()"
            @change="handleChange($event, 'protocol')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('hosts')" class="label-in-top">
          <p>{{t('Add one or more hosts (domains). This will be enabled only in the case http or https protocols are included in Application Protocol section')}}</p>
          <autocomplete-tag-input
            :inputType="'CertificateName'"
            :value="!checkIfHostsIsDisabled() ? rule.hosts : []"
            :placeholder="t('hosts')"
            :disabled="checkIfHostsIsDisabled()"
            @change="handleChange($event, 'hosts')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('ip protocol')" class="label-in-top">
          <p>
            {{t('ip_protocol_subtitle')}}
          </p>
          <autocomplete-tag-input
            :inputType="'IP Protocol'"
            :value='getInputValue()'
            :options='ipProtocolOptions'
            :placeholder="t('ip protocol')"
            :disabled="!isHostsEmpty()"
            @change="handleChange($event, 'ip_protocol')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
  </card>
</template>

<script>
  import { Input, FormItem } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'el-form-item': FormItem,
      'autocomplete-tag-input': AutocompleteTagInput
    },
    props: {
      rule: {
        type: Object,
        default: () => {}
      },
      devicesAndServices: {
        type: Array,
        default: () => []
      },
      applicationProtocolOptions: {
        type: Array,
        default: () => []
      },
      ipProtocolOptions: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
      },
      checkIfHostsIsDisabled() {
        const appProtocol = this.rule.protocol;
        const ipProtocol = this.rule.ip_protocol;
        const http = appProtocol.findIndex(item => item.toLowerCase() === 'http') >= 0;
        const https = appProtocol.findIndex(item => item.toLowerCase() === 'https') >= 0;
        const oneAppProtocol = appProtocol.length === 1 && (http || https);
        const twoAppProtocol = appProtocol.length === 2 && http && https;
        const isIpProtocolEmpty = ipProtocol.length === 0;
        if ((oneAppProtocol || twoAppProtocol) && isIpProtocolEmpty) return false;
        return true;
      },
      isHostsEmpty() {
        return !(this.rule.hosts.length > 0);
      },
      getInputValue() {
        let ipProtocol = _.cloneDeep(this.rule.ip_protocol);
        ipProtocol.forEach((element, i) => {
          if (!isNaN(element)) {
            element = this.t('autocomplete-tag-input.otherIpProtocol') + ':' + element;
            ipProtocol[i] = element;
          }
        });
        return ipProtocol;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mac-word {
    color: #51a427;
  }
  .devices-word {
    color: #2c6fb4;
  }
  .ipv4-word {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
</style>
