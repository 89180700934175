<template>
  <div>
    <br>
    <div class="row no-gutters" style="padding:0px;">
      <div class="col-md-12">
        <el-form-item :label="t('Position:')" label-width="70px" prop="position">
          <el-select v-model="position.type" class="banner-option-select select-primary">
            <el-option
              v-for="option in positionOptions"
              :key="option.value"
              :label="t(option.label)"
              :value="option.value"
              :disabled="option.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="row no-gutters" style="padding:0px;">
      <div class="col-12 flex-content">
        <el-form-item :label="sizeTitle" label-width="70px" :prop="sizeTitle"
          :rules="{ required: true, trigger: ['change', 'blur'], validator: sizeValidator }"
        >
          <el-input
            type="number"
            min="0"
            v-model="position.width.number"
            :class="errorMessage.bannerSize ? 'errorMessage': ''"
            class="banner-input"
          ></el-input>
          <p v-if="errorMessage.bannerSize" class="errorMessage">{{errorMessage.bannerSize}}</p>
          <el-select v-model="unit" class="banner-option-select-2 select-primary">
            <el-option
              v-for="option in widthOptions"
              :key="option.value"
              :label="t(option.label)"
              :value="option.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import { PaginatedTable } from "@/components";

export default {
  name: "autonotice-banner",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    PaginatedTable
  },
  props: {
    position: {
      type: Object,
      default: () => {
        return {
          type: "left",
          width: {
            number: 20,
            unit: "percentage"
          },
          height: {
            number: 20,
            unit: "percentage"
          }
        }
      }
    }
  },
  data() {
    return {
      positionOptions: [
        { value: "left", label: "Left" },
        { value: "right", label: "Right" },
        { value: "top", label: "Top" },
        { value: "bottom", label: "Bottom" }
      ],
      widthOptions: [
        { value: "percentage", label: "percentage"},
        { value: "pixels", label: "pixels"}
      ],
      errorMessage: "",
      name: "",
      url: "",
      size: {
        width: {
          number: 20,
          unit: "percentage"
        },
        height: {
          number: 20,
          unit: "percentage"
        }
      }
    }
  },
  computed: {
    sizeTitle() {
      return ["left","right"].includes(this.position.type)
        ? this.t('width')
        : this.t('height');
    },
    unit: {
      get(){
        try{
          return this.t(this.position.width.unit)
        }
        catch(err){
          return ''
        }
      },
      set(newValue){
        this.position.width.unit = newValue
      }
    }
  },
  methods: {
    sizeValidator(rule, value, callback) {
      if (this.position.width.number > 0) callback();
      else callback(new Error(this.t('mandatory')));
    }
  }
};
</script>
<style scoped>
  .container {
    max-width: 100%;
    min-width: 25em;
  }
  .card {
    justify-content: space-between;
  }
  .flex-content {
    display: flex;
    justify-content: flex-start;
  }
  .banner-option-select {
    margin-top: -0.8em;
    width: 70%;
    max-width: 26.4em;
  }
  .banner-option-select-2 {
    margin-top: -0.8em;
    width: 25%;
  }
  .banner-input {
    width: 35%;
  }
</style>
