<template>
  <card class="automotices__main-card padding-1">
    <h6 class="policy-card-title">
        <svgicon class="icon" icon="rule-actions" width="16" height="16" />
        <span style="padding-left:1em">{{t('rule actions')}}</span>
    </h6>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('status')">
        <div class="item-alignment">
          <n-switch
            v-model='status'
            @input="handleChange($event, 'status')"
            color="allot"
          ></n-switch>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="150px" :label="t('action')" prop='action' :show-message="false">
        <div class="item-alignment">
          <el-select
            :value="rule.action.type"
            placeholder="Select an action"
            @input="handleChange($event, 'type')"
            class="rule-action-select select-primary"
          >
            <el-option
              v-for="option in actionOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around" v-if="['redirect', 'banner', 'smartbanner'].includes(rule.action.type)">
      <el-form-item class="form-item" label-width="150px" :label="t(rule.action.type + '_list')" prop='action'>
        <div class="item-alignment">
           <el-select
            v-if="rule.action.type === 'redirect'"
            v-model="rule.action.value"
            :placeholder="this.t('Select redirect')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in bannerOptions.redirections"
              :key="option"
              :label="option"
              :value="option"
             >
            </el-option>
          </el-select>
            <el-select
            v-if="rule.action.type === 'banner'"
            v-model="rule.action.value"
            :placeholder="t('Select banner')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in bannerOptions.banners"
              :key="option"
              :label="option"
              :value="option"
             >
            </el-option>
          </el-select>
            <el-select
            v-if="rule.action.type === 'smartbanner'"
            v-model="rule.action.value"
            :placeholder="t('Select smart banner')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in bannerOptions.smartbanners"
              :key="option"
              :label="option"
              :value="option"
             >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="140px" :label="t('log activity')">
        <div class="item-alignment">
          <el-checkbox
            :value='rule.log_activity'
            @input="handleChange($event,'log_activity')"
            :placeholder="t('log activity')"
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
  </card>
</template>

<script>
  import { FormItem, Checkbox, Select, Option, InputNumber } from 'element-ui';
  import Switch from '@/components/Switch';
  export default {
    name: 'main-autonotices-config-card',
    components: {
      'el-checkbox': Checkbox,
      'el-form-item': FormItem,
      'n-switch': Switch,
      'el-select': Select,
      'el-option': Option,
      'el-input-number': InputNumber
    },
    data() {
      return {
        actionOptions: [
          {value: '', label: this.t('Select an action'), disabled: true},
          {value: 'redirect', label: this.t('Redirect to')},
          {value: 'banner', label: this.t('Banners Insertion')},
          {value: 'smartbanner', label: this.t('Smart Banners')},
          {value: 'allow', label: this.t('Allow')},
        ],
        configurations: {}
      }
    },
    props: {
      rule: {
        type: Object,
        default: () => {
          return {
            log_activity: true,
            status: false,
            apply_policy: false,
            action: { type: 'redirect' }
          }
        }
      },
      bannerOptions: {
        type: Object,
        default: () => ({ redirections: [], banners: [], smartbanners: []})
      }
    },
    computed: {
      status: {
        get() { 
          return this.rule.status 
        },
        set(newRuleStatus) {
          let updatedRule = _.cloneDeep(this.rule);
          updatedRule.status = newRuleStatus;
          this.$emit('rule-change', updatedRule);
        }
      },
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        if (input === 'type') {
          updatedRule.action.type= event;
          updatedRule.action.value = undefined;
        } else if (input === 'value')
          updatedRule.action.value = event;
        else 
          updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
      },
    },
  }
</script>

<style lang='scss'>
  .automotices__main-card.card {
    font-family: "AmpleSoft Pro Medium", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    border-radius: 0.5em;
    .card-body { margin-top: 1em; }
    .form-item { width: 70%; }
  }
</style>
