<template>
  <div>
    <page-title-card
      :page-title="t(type + ' title')"
      :page-description="t(type + ' Info')"
    >
      <template #button>
        <n-button
          type="primary"
          size="md"
          round
          block
          @click.native="handleNew"
          :visible_for_permissions='[{actions: ["POST"], path: "/subsystems/ns/autonotices/" + getType}]'
        >
          <i><svgicon class="icon" icon="icon-new"/></i>
          {{ t('New') }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px;">
        <paginated-table
          :rows="rows"
          :columns="realColumns"
          :actions="actions"
          :searchable="false"
          @item-edit="handleEdit"
          @item-remove="handleRemove"/>
      </b-card-body>
      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t('Update now') }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin"/>
          {{ t('Updating') }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import { PaginatedTable } from "@/components";
import { ListFormatter } from "@/components/Tables/Formatters"
import AllowedBlockedTable from '@/components/Tables/AllowedBlockedTable'
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "autonotices-advanced-configuration",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    PaginatedTable,
    "allowed-blocked-table": AllowedBlockedTable,
    PageTitleCard
  },
  props: ["type", "routename"],
  data() {
    return {
      updating: false,
      configuration: [],
      actions: {
        minWidth: 100,
        label: this.t("Actions"),
        fixed: "right",
        items: [
          {
            type: "warning",
            icon: "now-ui-icons business_badge",
            event: "item-edit",
            action: this.t("Edit")
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            action: this.t('Remove'),
            message: this.t("remove confirmation"),
            confirm: true,
            event: "item-remove", visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/autonotices/redirections/.*"}]
          }
        ],
        editItems: [
          {
            type: "success",
            icon: "now-ui-icons business_badge",
            event: "edit-save",
            action: this.t("Save")
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            action: this.t("Cancel"),
            event: "edit-cancel"
          }
        ]
      },
      editingRow: null,
    };
  },
  methods: {
     refresh(wait){
       this.getRowsData()
    },
    handleRemove(data) {
      this.$store.dispatch("networksecure/autoNotices/DeleteConfiguration", { type: this.type, id: data.row.name })
      .then( res => {
        this.getRowsData();
      })
      .catch(error => {});
    },
    handleNew(event) {
      this.$router.push( { name: this.routename + ' / Create'})
    },
    handleEdit(data) {
      const id = data && data.row ? data.row.name : data
      this.$router.push( { name: this.routename + ' / Edit', params: { id } })
    },
    async getRowsData() {
      try {
        this.updating = true
        this.$store.dispatch("networksecure/autoNotices/getAutoNoticesAdvancedConfiguration", { type: this.type})
        .then(res => {
          this.updating = false
          this.configuration = res
        })
        .catch( err => {
          this.updating = false
        })

      } catch (e) {
        this.updating = false
        console.error("Error getting rows data--> ", e);
      }
    },
    getBannerUrl(banner){
      let ret = banner.url + ' : position ' + banner.position + ', ' +'size ' + banner.width;
      if ( banner.unit == 'pixels')
        ret = ret + 'px'
      else
        ret = ret + '%'

      return ret
    },
    getBannerIcon(banner){
      if ( banner.SmartBannerType === 'SmartBannerIcon')
        return banner.show || ''
      else
        return null
    }
  },
  computed: {
      rows(){
        let ret = null
        let _vm = this
        switch(this.type){
          case 'redirections':
            ret = this.configuration.map(element => ({
                        name: element.autonotice_list_id,
                        url: element.items,
                        redirection: this.t(element.mode)})
            );
            break;
          case 'banners':
            ret = this.configuration.map(element => ({
                        name: element.autonotice_list_id,
                        url: element.items.map(banner => { return _vm.getBannerUrl(banner)}),
                        mode: this.t(element.mode)})
            );
            break;
          case 'smartbanners':
            ret = this.configuration.map(element => ({
                        name: element.autonotice_list_id,
                        url: element.items.map(banner => { return _vm.getBannerIcon(banner)}).filter(item => (item != null)),
                        mode: this.t(element.mode)})
            );
            break;
          default:
            console.error(this.name + ': incorrect type (' + this.type + ')')
        }
        return ret;
      },
      layout() {
        return this.$store.getters['settings/layout'];
      },
      realColumns: {
          get(){
              if (this.layout == 'desktop'){
                  return this.columns
              }
              else if ( this.layout == 'tablet'){
                  return [ this.columns[0], this.columns[1]]
              }
              else{
                  return [ this.columns[0], this.columns[1]]
              }
          }
      },
      columns() {
        switch(this.type){
          case 'redirections':
            return (
              [
                {prop: "name", label: this.t("LIST"), minWidth: 130 },
                {prop: 'url', label: this.t('DETAILS'), minWidth: 200, formatter: ListFormatter },
                {prop: 'redirection', label: this.t('MODE'), minWidth: 100 }
              ]);
          case 'banners':
            return (
              [
                {prop: "name", label: this.t("BANNER LIST"), minWidth: 100 },
                {prop: 'url', label: this.t('DETAILS'), minWidth: 250, formatter: ListFormatter },
                {prop: 'mode', label: this.t('MODE'), minWidth: 100 }
              ]);
          case 'smartbanners':
            return (
              [
                {prop: "name", label: this.t("SMARTBANNER LIST"), minWidth: 120 },
                {prop: 'url', label: this.t('DETAILS'), minWidth: 250, formatter: ListFormatter },
                {prop: 'mode', label: this.t('MODE'), minWidth: 100 }
              ]);
          default:
            console.error(this.name + ': incorrect type (' + this.type + ')')
        }
      },
      getType() {
        return this.type;
      }
  },
  created() {
    this.getRowsData();
  }};
</script>
<style scoped>
.container {
  max-width: 100%;
  min-width: 25em;
}
.card {
  justify-content: space-between;
}
.refresh {
  cursor: pointer;
  margin-left: 2em;
  margin-top: 1em;
}
</style>
