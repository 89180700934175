<template>
  <service-policies
    :columns="columns"
    iconName="item-antiphishing"
    containerId="div-antiPhishing"
    storeModuleName="antiPhishing"
    servicePath="Malware&Phishing"
    serviceName="Malware & Phishing"
    :createButtonPermissions='[{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}]'
    :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/isp-strategy' }]"
  ></service-policies>
</template>

<script>
  import ServicePolicies from '@/components/ServicePolicies/ServicePolicies';
  import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
  import Tags from '@/components/Tables/Formatters/Tags';
  import SchedulerFormatter from '@/components/Tables/Formatters/Scheduler';

  export default {
    name: 'page-antiPhishing',
    components: {
        'service-policies': ServicePolicies
    },
    data() {
      return {
        columns: [
          { prop: 'rule_name', label: this.t('name'), minWidth: 130 },
          { prop: 'status', label: this.t('status'), minWidth: 100, formatter: Switch, visible_for_permissions: [{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}] },
          { prop: 'source', label: this.t('source'), minWidth: 180, formatter: Tags, align: 'center' },
          { prop: 'threat', label: this.t('threats'), minWidth: 120, formatter: Tags, align: 'center' },
          { prop: 'allowed_forbidden', label: this.t('A&F-lists'), minWidth: 120, formatter: Tags, align: 'center' },
          { prop: 'action', label: this.t('action'), minWidth: 100, formatter: Tags, align: 'center' },
          { prop: 'schedule', label: this.t('schedule'), minWidth: 200, formatter: SchedulerFormatter, align: 'center' },
        ]
      }
    }
  }
</script>

<style>
</style>