<template>
  <card class="antivirus__rule-config-card padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            {{this.t('Add one or more')}} <span class="devices-word">{{this.t('devices')}}</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> {{this.t('or')}} <span class="ipv6-word">{{this.t('ipv6')}}</span> {{this.t('with or without mask. Leave empty for "Any"')}}
          </p>
          <autocomplete-tag-input
            :inputType="'Source'"
            :placeholder="t('source')"
            :value='rule.source'
            :options='devicesAndServices'
            :protocol="rule.protocol"
            :checks="checks"
            @change="handleChange($event, 'source')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row" v-if="false">
      <div class="col-md-12">
        <el-form-item :label="t('protocol')" class="label-in-top">
          <el-select
            :value="rule.protocol"
            placeholder="Select a protocol"
            @input="handleChange($event, 'protocol')"
          >
            <el-option
              v-for="option in protocolOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="row" ref="radioGroupParent2">
      <div class="col-md-12">
        <el-form-item class="label-in-top" :label="t('threats')" >
          <p>{{ this.t('av_threats_help') }}</p>
          <el-radio-group v-model="threats">
            <el-radio-button label="none">{{this.t('none')}}</el-radio-button>
            <el-radio-button label="infected">{{this.t('file infected')}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
    <div class="row" ref="radioGroupParent">
      <div class="col-md-12">
        <el-form-item class="label-in-top" :label="t('advanced')" >
          <p>{{ this.t('av_advanced_help') }}</p>
          <el-radio-group v-model="advanced">
            <el-radio-button label="none">{{this.t('none')}}</el-radio-button>
            <el-radio-button label="password_compressed">{{this.t('password protected file detection')}}</el-radio-button>
            <el-radio-button label="allowed_list">{{ t('if-in-allowed') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
  </card>
</template>

<script>
  import { Input, FormItem, Select, RadioButton, RadioGroup, Option } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  import Switch from '@/components/Switch';
  import _ from 'lodash';
  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'n-switch': Switch,
      'el-select': Select,
      'el-option': Option,
      'el-form-item': FormItem,
      'el-radio-group': RadioGroup,
      'el-radio-button': RadioButton,
      'autocomplete-tag-input': AutocompleteTagInput
    },
    props: {
      rule: {
        type: Object,
        default: {
          source: [],
          destination: [],
          protocol: '',
          advanced: 'none',
          threats: 'infected'
        }
      },
      devicesAndServices: {
        type: Array,
        default: () => []
      },
      protocolOptions: {
        type: Array,
        default: () => []
      },
      categoryOptions: {
        type: Array,
        default: () => []
      }
    },
    mounted(){
      this.setAdvancedFromThreats(this.rule.threats)
      this.$nextTick(function() {
        window.addEventListener('resize', this.setButtonsGroupPosition);
        this.setButtonsGroupPosition()
      })
    },
    computed: {
      checks: {
        get() {
          let elements = ['devices', 'ip'];
          this.rule.hasOwnProperty('protocol') && this.rule.protocol.toLowerCase() === 'mail' && elements.push('mail', 'mail-domain')
          return elements;
        }
      },
      threats: {
        get(){
          return this.rule.threats
        },
        set(value){
          this.setAdvancedFromThreats(value)
        }
      },
      advanced: {
        get(){
          return this.rule.advanced
        },
        set(value){
          this.setThreatsFromAdvanced(value)
        }
      }
    },
    methods: {
      setAdvancedFromThreats(value){
        let updatedRule = _.cloneDeep(this.rule)
        updatedRule.threats = value
        switch(value){
        case 'infected':
          if ( this.rule.advanced != 'none'){
            updatedRule.advanced = 'none';
          }
          break;
        case 'none':
          if ( this.rule.advanced != 'allowed_list'){
            updatedRule.advanced = 'allowed_list';
          }
          break;
        }
        this.$emit('rule-change', updatedRule);
      },
      setThreatsFromAdvanced(value){
        let updatedRule = _.cloneDeep(this.rule)
        updatedRule.advanced = value
        switch(value){
          case 'none':
            if ( this.rule.threats != 'infected'){
              updatedRule.threats = 'infected';
            }
            break;
          case 'password_compressed':
          case 'allowed_list':
            if ( this.rule.threats == 'infected'){
              updatedRule.threats = 'none';
            }
            break;
        }
        this.$emit('rule-change', updatedRule);
      },
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
      },
      checkIfHostsIsDisabled() {
        const appProtocol = this.rule.protocol;
        const ipProtocol = this.rule.ip_protocol;
        const http = appProtocol.findIndex(item => item.toLowerCase() === 'http') >= 0;
        const https = appProtocol.findIndex(item => item.toLowerCase() === 'https') >= 0;
        const oneAppProtocol = appProtocol.length === 1 && (http || https);
        const twoAppProtocol = appProtocol.length === 2 && http && https;
        const isIpProtocolEmpty = ipProtocol.length === 0;
        if ((oneAppProtocol || twoAppProtocol) && isIpProtocolEmpty) return false;
        return true;
      },
      isHostsEmpty() {
        return !(this.rule.hosts.length > 0);
      },
      setButtonsGroupPosition() {
        if(this.$refs.radioGroupParent.offsetWidth < 500){
          this.$refs.radioGroupParent.classList.add('vertical-buttons')
        } else {
          this.$refs.radioGroupParent.classList.remove('vertical-buttons')
        }
        if(this.$refs.radioGroupParent2.offsetWidth < 500){
          this.$refs.radioGroupParent2.classList.add('vertical-buttons')
        } else {
          this.$refs.radioGroupParent2.classList.remove('vertical-buttons')
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setButtonsGroupPosition);
    }
  }
</script>

<style lang="scss" scoped>
  .services-word {
    color: #5b136d;
  }
  .mac-word {
    color: #51a427;
  }
  .devices-word {
    color: #2c6fb4;
  }
  .ipv4-word {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
</style>

<style lang="scss">
  .vertical-buttons{
    .el-radio-group{
      display: flex;
      flex-direction: column;
      justify-content: center;
      label {
        margin: 0 auto;
      }
      .el-radio-button__inner{
        width: 300px;
        border: 1px solid #DCDFE6;
        margin: 2px;
      }
    }
  }
  .antivirus__rule-config-card {
    .rule-protocol-select { width: 100%; }
    @media (max-width: 1700px) {
      .main-label-position {
        label {
          width: auto!important;
          float: none;
          display: inline-block;
          text-align: left;
          padding: 0;
          margin: 0;
        }
        div { margin-left: 0!important; }
      }
    }
  }
</style>
