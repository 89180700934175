<template>
  <div>
    <page-title-card
      :page-title="t('antibotnet_configuration')"
    >
      <template #button>
        <n-button
          type="primary"
          @click.native="submitForm('form')"
          class="btn btn-round btn-primary"
          style="width: -webkit-fill-available"
          :visible_for_permissions="[
              {
                actions: ['PATCH'],
                path: '/subsystems/ns/service-configuration',
              },
            ]"
        >
          {{ t("save") }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body class="antibotnet__advanced">
      <el-form
        ref="form"
        :model="advancedConfiguration"
        :rules="formRules"
        label-width="140px"
        label-position="top"
        style="padding-left: 30px; padding-right: 30px; padding-top: 30px"
      >
        <div class="row">
          <div class="col-12">
            <el-form-item
              :label="t('custom_blocking_page_url')"
              prop="custom_blocking_page_url"
            >
              <div class="page-mode-div">
                <el-select
                  v-model="advancedConfiguration.page_mode"
                  class="page-mode-select"
                >
                  <el-option
                    v-for="option in modeOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="advancedConfiguration.page_mode === 'custom'"
                  v-model="advancedConfiguration.custom_blocking_page_url"
                  class="form-group no-height form-control-lg input-group page-mode-input"
                ></el-input>
                <el-input
                  v-else
                  disabled
                  :value="`${default_blocking_page_url.name} - ${default_blocking_page_url.blocking_page_url}`"
                  class="form-group no-height form-control-lg input-group page-mode-input"
                ></el-input>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <b-modal
        id="modal-new"
        ref="modal"
        :title="t('advanced-configuration')"
        @ok="getData" :ok-title="t('accept')" :cancel-title="t('cancel')"
      >
        <div>{{ t("confirmation-text") }}</div>
      </b-modal>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="cancel"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("update-now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("updating...") }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";
import _ from "lodash";

export default {
  name: "anti-botnet-advanced-configuration",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    PageTitleCard
  },
  props: [""],
  data() {
    return {
      advancedConfiguration: {
        page_mode: "default",
        custom_blocking_page_url: "",
      },
      default_blocking_page_url: {
        name: "",
        blocking_page_url: "",
      },
      updating: false,
      formRules: {
        custom_blocking_page_url: [
          {
            type: "url",
            message: this.t("url_field"),
            trigger: ["blur", "change"],
          },
        ],
      },
      modeOptions: [
        { value: "default", label: this.t("default") },
        { value: "custom", label: this.t("custom") },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.updating = true;
        await this.$store.dispatch(
          "networksecure/antiBotnet/getAdvancedConfiguration"
        );
        let config = _.cloneDeep(
          this.$store.getters[
            "networksecure/antiBotnet/getAdvancedConfiguration"
          ]
        );
        this.advancedConfiguration = {
          page_mode: config.blocking_page.page_mode,
          custom_blocking_page_url:
            config.blocking_page.custom_blocking_page_url,
        };
        await this.$store.dispatch(
          "networksecure/antiBotnet/getDefaultBlockingUrl"
        );
        this.default_blocking_page_url = this.$store.getters[
          "networksecure/antiBotnet/getDefaultBlockingUrl"
        ];

        this.updating = false;
      } catch (e) {
        console.error(
          "Error getting anti botnet advanced configuration data--> ",
          e
        );
        this.updating = false;
      }
    },
    submitForm(formName) {
      this.updating = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.$store.dispatch(
            "networksecure/antiBotnet/updateAntiBotnetAdvancedConfiguration",
            this.advancedConfiguration
          );
          this.updating = false;
        } else {
          console.error("Validation error");
          this.updating = false;
          return false;
        }
      });
    },
    cancel() {
      this.$refs.modal.show();
    },
  },
};
</script>
<style lang="scss">
  .antibotnet__advanced {
    @media (min-width: 600px) {
      .page-mode-div {
        display: flex;
        align-items: baseline;
        padding: 5px;
      }
      .page-mode-select,
      .page-mode-input {
        margin: 5px;
      }
    }
    .page-mode-select .el-input .el-input__inner {
      background: #f57448!important;
    }
  }
</style>
