<template>
  <service-policy-edit
    serviceName="firewall"
    storeModuleName="firewall"
    pathName="Firewall"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
  >
    <template v-slot:main-card="slotProps">
      <main-firewall-config-card
        :rule="slotProps.rule"
        @rule-change="slotProps.handleRuleChange"
      ></main-firewall-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule="slotProps.rule"
        :devicesAndServices='devicesAndServices'
        :applicationProtocolOptions="applicationProtocolOptions"
        :ipProtocolOptions="ipProtocolOptions"
        @rule-change="slotProps.handleRuleChange"
        @search-suggestions="searchSuggestions"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
import Vue from "vue";
import { Form, FormItem, Input, Button } from "element-ui";
import ServicePolicyEdit from "@/components/ServicePolicies/ServicePolicyEdit";
import MainCard from "./components/MainCard";
import RuleConfigCard from "./components/RuleConfigCard";
// import { generateScreenshot } from "@/util/generateScreenshot";

export default {
  name: "firewall-edit",
  components: {
    "el-form": Form,
    "el-input": Input,
    "el-button": Button,
    "el-form-item": FormItem,
    "main-firewall-config-card": MainCard,
    "rule-config-card": RuleConfigCard,
    "service-policy-edit": ServicePolicyEdit,
  },
  data() {
    return {
      ruleId: undefined,
      rule: {
        rule_name: "",
        status: false,
        action: { type: "allow" },
        log_activity: true,
        source: [],
        destination: [],
        protocol: [],
        hosts: [],
        ip_protocol: [],
        schedule: {
          weekdays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
          startTimeSlot: "",
          endTimeSlot: "",
          startDateSlot: "",
          endDateSlot: "",
        },
      },
      devicesAndServices: [],
      applicationProtocolOptions: [
        { value: "WAP" },
        { value: "POP" },
        { value: "FTP" },
        { value: "DNS" },
        { value: "BGP" },
        { value: "HTTP" },
        { value: "IMAP" },
        { value: "SMTP" },
        { value: "MQTT" },
        { value: "AMQP" },
        { value: "COAP" },
        { value: "FTPS" },
        { value: "TFTP" },
        { value: "HTTPS" },
        { value: "DNSTCP" },
        { value: "WEBSOCKET" },
        { value: "DHCP" },
        { value: "QUIC" },
      ],
      ipProtocolOptions: [
        { value: "ICMP/ICMPv6" },
        { value: "GRE" },
        { value: "L2TP" },
        { value: "IP-in-IP" },
        { value: "ENCAP" },
        { value: "ESP" },
        { value: "AH" },
        { value: "RDP" },
        { value: "IGMP" },
        { value: "TCP" },
        { value: "UDP" },
        { value: "TCP/UDP" },
      ],
    };
  },
  methods: {
    actionValidator(rule, value, callback) {
      console.log("actionValidator -> value", value);
      if (value.type && value.type !== "timeout") callback();
      if (
        value.type &&
        value.type === "timeout" &&
        value.value &&
        value.value >= 5 &&
        value.value <= 50000
      )
        callback();
      if (
        value.type &&
        value.type === "timeout" &&
        (!this.rule.action.value ||
          this.rule.action.value < 5 ||
          this.rule.action.value > 50000)
      )
        callback(
          new Error(
            this.t(
              "The timeout value is not valid. Please enter an integer number between 5 and 50000"
            )
          )
        );
      if (!value.type || value.type === "")
        callback(new Error(this.t("please select an action")));
    },
    async searchSuggestions(data) {
        const queryString = data.queryString;
        const devicesQuery = {
          field_name: 'ns_id',
          field_operator: "sw",
          field_value: queryString,
          sort: 'asc'
        };
        let devicesAndServices = [];
        const devicesResult = await this.$store.dispatch('accountManagement/devices/getDevices', {query: devicesQuery});
        devicesResult.items.forEach(item => {
          const deivceNSid = _.get(item, "product_data.NetworkSecure.ns_id")
          if(deivceNSid && deivceNSid !== "-"){
            devicesAndServices.push({value: deivceNSid, type: 'device'})
          }
        })

        this.devicesAndServices = devicesAndServices
          .sort((a, b) => (a.value > b.value) ? 1 : -1)
          .slice(0, 10);
        data.done();
    },
  },
  async created() {
    const ruleId = this.$route.params.pathMatch;
    if (ruleId && ruleId !== "") this.ruleId = ruleId;
  },
};
</script>
