<template>
  <div v-if="typeof row[column.prop] === 'object' && row[column.prop] !== null">
    <div v-if="row[column.prop].startDateSlot" class="hours">
      {{ t(startDate.slice(0,(startDate.indexOf(startDate.match(/(\d+)/)[0]) - 1))) + " " + startDate.slice(startDate.indexOf(startDate.match(/(\d+)/)[0]), startDate.length) }} - {{ t(endDate.slice(0,(endDate.indexOf(endDate.match(/(\d+)/)[0]) - 1))) + " " + endDate.slice(endDate.indexOf(endDate.match(/(\d+)/)[0]), endDate.length) }}
      <!--{{startDate}} - {{endDate}}-->
    </div>
    <div class="scheduler-container">
      <template v-for="(value, key) in row[column.prop].weekdays">
        <span v-if="value" :key=key class="weekday-circle">
          {{t(key[0].toUpperCase() + key[1])}}
        </span>
      </template>
    </div>
    <div v-if="row[column.prop].startTimeSlot" class="hours">
      {{row[column.prop].startTimeSlot}} - {{row[column.prop].endTimeSlot}}
    </div>
  </div>
  <div v-else-if="row[column.prop] === 'always'">
    <el-tag class="default-tag">{{t('any')}}</el-tag>
  </div>
</template>

<script>
  import { Tag } from "element-ui";
  import moment from 'moment';

  export default {
    name: 'scheduler-formatter',
    components: {
      'el-tag': Tag
    },
    props: {
      row: {
        type: Object,
        default: () => {}
      },
      column: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        dateFormat: "MMM DD, YYYY"
      }
    },
    computed: {
      startDate() {
        return this.row[this.column.prop].startDateSlot
          ? moment(this.row[this.column.prop].startDateSlot).format(this.dateFormat)
          : ''
      },
      endDate() {
        return this.row[this.column.prop].endDateSlot
          ? moment(this.row[this.column.prop].endDateSlot).format(this.dateFormat)
          : ''
      }
    }
  }
</script>

<style scoped lang=scss>
  .scheduler-container {
    display: flex;
    justify-content: center;
  }
  .weekday-circle {
    background-color: #f0f9eb;
    color: #67c23a;
    border: 1px solid #e1f3d8;
    border-radius: 50px;
    font-size: 0.8em;
    width: 2.1em;
    height: 2.1em;
    margin: 0em 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hours {
    text-align: center;
  }
  .default-tag {
    background-color: #cfcfcf;
    border-color: #a7a7a8;
    color: #444444;
    height: auto;
  }
</style>