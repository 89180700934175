<template>
  <card class="autonotices__rule-config-card padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            {{t('Add one or more')}} <span class="devices-word">{{t('devices')}}</span>, 
              <span class="services-word">{{t('autonotices packages')}}</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> {{t('or')}} 
             <span class="ipv6-word">{{this.t('ipv6')}}</span> {{t("with or without mask. Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'device', 'package']"
            :inputType="'Source'"
            :value='rule.source'
            :placeholder="t('source')"
            :options='sourceSuggestions'
            @change="handleChange($event, 'source')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('Category')" class="label-in-top">
          <p>
            {{t("Add one or more categories such as social networks, violence... Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :value='rule.category'
            :options='categoryOptions'
            :placeholder="t('Category')"
            @change="handleChange($event, 'category')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('urls')" class="label-in-top">
          <p>
            {{t("Add one or more URLs (or domains). If the URL has to be excluded type")}}
            <span class="not-url">{{'not:'}}</span> {{t("and the URL")}}
          </p>
          <autocomplete-tag-input
            inputType='url'
            :value='rule.defined_urls'
            :placeholder="t('urls')"
            @change="handleChange($event, 'defined_urls')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('frequency')" class="label-in-top">
          <p>{{t("Set the display and delay time when you want the policy to be triggered")}}</p>
          <p class="frequency-title">{{t('display-frequency')}}</p>
          <el-slider
            v-model="display"
            :min="0" :max="Object.keys(DISPLAY_OPTIONS).length - 1"
            :format-tooltip="formatDisplayToolTip"
          ></el-slider> 
          <p>{{ formatDisplayToolTip(display) }}</p>
          <br>
          <p>{{t('delay-frequency')}}</p>
          <el-slider
            v-model="delay"
            :min="0" :max="Object.keys(DELAY_OPTIONS).length - 1"
            :format-tooltip="formatDelayToolTip"
          ></el-slider>
          <p>{{ formatDelayToolTip(delay) }}</p>
       </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('A&F-lists')" class="label-in-top">
          <p>{{ this.t('A&F_help') }}</p>
          <el-radio-group v-model="rule.allowed_forbidden" @change="handleChange($event, 'allowed_forbidden')"> 
            <el-radio-button label="none">{{ t('none') }}</el-radio-button>
            <el-radio-button label="allowed_list">{{ t('if-in-allowed') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
  </card>
</template>

<script>
  import { Input, FormItem, Slider } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  import { DISPLAY_OPTIONS, DELAY_OPTIONS } from '@/util/autoNoticesUtils.js';

  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'el-form-item': FormItem,
      'autocomplete-tag-input': AutocompleteTagInput,
      'el-slider': Slider
    },
    data() {
      return {
        DISPLAY_OPTIONS: DISPLAY_OPTIONS,
        DELAY_OPTIONS: DELAY_OPTIONS,
      }
    },
    props: {
      rule: {
        type: Object,
        default: () => {}
      },
      sourceSuggestions: {
        type: Array,
        default: () => []
      },
      categoryOptions: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      displayOptions(){
        let ret = {}
        let index = 0
        Object.keys(this.DISPLAY_OPTIONS).forEach( key => {
            ret[index] = this.t(this.DISPLAY_OPTIONS[key])
            index += 1
        });
        return ret
      },
      delayOptions(){
        let ret = {}
        let index = 0
        Object.keys(this.DELAY_OPTIONS).forEach( key => {
            ret[index] = this.t(this.DELAY_OPTIONS[key])
            index += 1
        });
        return ret
      },
      display: {
        get(){
          let freq = this.rule.frequency
          if ( !!!freq)
            return 0;
          freq = freq.display
          if ( !!!freq || !!! freq.when || freq.when === 'always')
            return 0;
          else if ( freq.when === 'once')
            return Object.keys(this.DISPLAY_OPTIONS).length - 1
          else{
            if ( !!!freq.seconds)
              freq.seconds = 300
            freq = freq.seconds/60
            let slot = Object.keys(this.DISPLAY_OPTIONS).indexOf(`${freq}`)
            if (slot >= 0)
              return slot
          
            // take the "ceil" slot of the value
            slot = Object.keys(this.DISPLAY_OPTIONS)[Object.keys(this.DISPLAY_OPTIONS).length - 1]
            Object.keys(this.DISPLAY_OPTIONS).some( item => {
              if (item > freq){
                slot = item
                return true
              }
            });
            return Object.keys(this.DISPLAY_OPTIONS).indexOf(`${slot}`)
           } 
        },
        set(val){
          if ( this.display != val){
            let updatedRule = _.cloneDeep(this.rule);
            let add = null
            if  (val === 0){
              add = { display : { when : 'always'}}
            }
            else if (val >= Object.keys(this.DISPLAY_OPTIONS).length - 1){
              add = { display : { when : 'once'}}
            }
            else{
              add = { display : { 
                        when : 'every',
                        seconds: Object.keys(this.DISPLAY_OPTIONS)[val]*60
                      }
              }
            }
            updatedRule.frequency = { 
                ...updatedRule.frequency,
                ...add
            }      
            this.$emit('rule-change', updatedRule);    
          }
        }
      },
      displayAsterisk: {
        get(){
          let freq = this.rule.frequency
          if ( !!!freq)
            return false;
          freq = freq.display
          if ( !!!freq || !!! freq.when || freq.when === 'always')
            return false;
          else if ( freq.when === 'once')
            return false
          else{
            if ( !!!freq.seconds)
              freq.seconds = 300
            freq = freq.seconds/60
            return Object.keys(this.DISPLAY_OPTIONS).indexOf(`${freq}`) < 0
          }
        }
      },
      delay: {
        get(){
          let freq = this.rule.frequency
          if ( !!!freq)
            return 0;
          freq = freq.delay
          if ( !!!freq)
            return 0;
          freq = freq/60

          let slot = Object.keys(this.DELAY_OPTIONS).indexOf(`${freq}`)
          if (slot >= 0)
            return slot
          
          // take the "ceil" slot of the value
          slot = Object.keys(this.DELAY_OPTIONS)[Object.keys(this.DELAY_OPTIONS).length - 1]
          Object.keys(this.DELAY_OPTIONS).some( item => {
            if (item > freq){
              slot = item
              return true
            }
          });
          return Object.keys(this.DELAY_OPTIONS).indexOf(`${slot}`)
        },
        set(val){
          if ( this.delay != val){
            let updatedRule = _.cloneDeep(this.rule);
            if (val === 0){
              if(!!updatedRule.frequency && !!updatedRule.frequency.delay){
                delete updatedRule.frequency.delay
                this.$emit('rule-change', updatedRule);
              }
            }
            else{
              updatedRule.frequency = { 
                ...updatedRule.frequency,
                delay : Object.keys(this.DELAY_OPTIONS)[val]*60 || 300
              }
              this.$emit('rule-change', updatedRule);   
            }
          }
        }
      },
      delayAsterisk: {
        get(){
          let freq = this.rule.frequency
          if ( !!!freq)
            return false;
          freq = freq.delay
          if ( !!!freq)
            return false;
          freq = freq/60

          return Object.keys(this.DELAY_OPTIONS).indexOf(`${freq}`) < 0
        }
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        switch(input){
          case  'category':
            // Category input show the translation, so we need to convert to get the real value to send to the backend
            let tempEvent = event.map(element => element.value ? element.value : element);
            let selected = this.categoryOptions.filter(option => tempEvent.includes(option.value) || tempEvent.includes(option.name));
            updatedRule[input] = selected.map( item => item.name);
            break;
          case 'defined_urls':
            updatedRule[input] = event.map( item => item.replace('http://','').replace('https://',''))
            break;
          default:
            updatedRule[input] = event;
        }
        this.$emit('rule-change', updatedRule);
      },
      formatDelayToolTip(val){
        if (!val)
          val = 0;

        let ret = this.t(Object.values(this.DELAY_OPTIONS)[val])
        if ( this.delayAsterisk)
          ret = ret + '*'
        
        return ret
      },
      formatDisplayToolTip(val){
        if (!val)
          val = 0;

        let ret = this.t(Object.values(this.DISPLAY_OPTIONS)[val])
        if ( this.displayAsterisk)
          ret = ret + '*'
        
        return ret
      },
    }
  }
</script>

<style lang="scss" scoped>
  .services-word {
    color: #5b136d;
  }
  .mac-word {
    color: #51a427;
  }
  .devices-word {
    color: #2c6fb4;
  }
  .ipv4-word, .not-url {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
  p.frequency-title {
    margin-top: 2em !important;
  }
</style>

<style lang="scss">
  .autonotices__rule-config-card {
    .el-slider__marks-text{
      white-space: nowrap;
    }
  }
</style>
