<template>
  <card class="padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            {{this.t('Add one or more')}} <span class="devices-word">{{this.t('devices')}}</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> {{this.t('or')}} <span class="ipv6-word">{{this.t('ipv6')}}</span> {{this.t("with or without mask. Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'device']"
            :inputType="'Source'"
            :placeholder="t('source')"
            :value='rule.source'
            :options='devicesAndServices'
            @change="handleChange($event, 'source')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('categories')" class="label-in-top">
          <p>{{ this.t('cf_categories_help') }}</p>
          <autocomplete-tag-input
            :value='rule.categories'
            :options='categoryOptions'
            :placeholder="t('categories')"
            @change="handleChange($event, 'categories')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('urls')" class="label-in-top">
          <p>
            {{ t('add_one_or_more') }} <span class="urls-domain-word">{{t('urls_or_domains')}}</span>.
            {{ t('url_not_excluded') }} <span class="not-word">{{t('not_word')}}</span> {{ t('and_url')}},
            {{ t('for_regular_exp') }} <span class="reg-word">{{t('reg_word')}}</span> {{t('and_the_regular_expression')}}.
            {{ t('note_exluded_regular') }} <span class="not-reg-word">{{t('not_reg_word')}}</span> {{t('and_the_regular_expression')}}.
          </p>
          <autocomplete-tag-input
            inputType="Urls"
            :value='rule.urls'
            :placeholder="t('urls')"
            @change="handleChange($event, 'urls')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('http_methods')" class="label-in-top">
          <p>{{ this.t('cf_http_methods_help') }}</p>
          <autocomplete-tag-input
            :value='rule.http_methods'
            :placeholder="t('http_methods')"
            :options='httpOptions'
            @change="handleChange($event, 'http_methods')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>
    <div class="row time-row">
      <div class="col-md-6">
        <el-form-item :label="t('time_limit')" class="label-in-top">
          <p>{{ this.t('cf_time_limit_help') }}</p>
          <el-input-number
            :value="rule.time_limit.value"
            @change="handleTimeLimitChange($event, 'value')"
            :min="0"
            :max="maximumValue"
          ></el-input-number>
        </el-form-item>
      </div>
      <div class="col-md-6 time-select">
        <el-select
          :value="rule.time_limit.type"
          class="select-primary"
          size="large"
          @change="handleTimeLimitChange($event, 'type')"
          :placeholder="t('time_type')"
        >
          <el-option
            v-for="option in timeLimitOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('A&F-lists')" class="label-in-top">
          <p>{{ this.t('A&F_help') }}</p>
          <el-radio-group v-model="rule.allowed_forbidden">
            <el-radio-button label="none">{{ t('none') }}</el-radio-button>
            <el-radio-button label="allowed_list">{{ t('if-in-allowed') }}</el-radio-button>
            <el-radio-button label="forbidden_list">{{ t('if-in-forbidden') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>

    <div class="row" v-if="sslEnabled">
      <div class="col-md-12">
        <el-form-item :label="t('advanced')" class="label-in-top">
          <p>
            {{ t('cf_advanced_help_1') }}
            <span class="not-word">{{t('not_word')}}</span>.
            {{ t('cf_advanced_help_2') }}
          </p>
          <autocomplete-tag-input
            :inputType="'Advanced'"
            :value='mappedAdvanced'
            :options='advancedOptions'
            @change="handleChange($event, 'advanced')"
            :placeholder="t('advanced')"
            :isWithLabel="true"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

  </card>
</template>

<script>
  import { Input, FormItem } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'el-form-item': FormItem,
      'autocomplete-tag-input': AutocompleteTagInput
    },
    props: {
      rule: {
        type: Object,
        default: () => {}
      },
      devicesAndServices: {
        type: Array,
        default: () => []
      },
      categoryOptions: {
        type: Array,
        default: () => []
      },
      httpOptions: {
        type: Array,
        default: () => []
      },
      advancedOptions: {
        type: Array,
        default: () => []
      },
      timeLimitOptions: {
        type: Array,
        default: () => [],
      },
      sslEnabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      maximumValue() {
        return this.rule.time_limit.type === "minutesPerDay" ? 1440 : 168
      },
      mappedAdvanced() {
        return this.rule.advanced.map(option => {
          return {
            type: option.type,
            value: option.value,
            label: this.t(`contentFilter-edit.${option.value}`)
          }
        });
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        switch(input){
          case  'categories':
            // Category input show the translation, so we need to convert to get the real value to send to the backend
            let tempEvent = event.map(element => element.value ? element.value : element);
            let selected = this.categoryOptions.filter(option => tempEvent.includes(option.value) || tempEvent.includes(option.name));
            updatedRule[input] = selected.map( item => item.name);
            break;
          case 'urls':
            updatedRule[input] = event.map( item => {
                return { type: item.type, value: item.value.replace('http://','').replace('https://','')}
            });
            break;
          case 'http_methods':
            updatedRule[input] = event;
            break;
          case 'advanced':
            if (event.length < this.rule.advanced.length) {
              updatedRule[input] = event;
              break;
            }
            let newAdvanced = [...this.rule.advanced];
            let inverseIndex = -1;
            event.forEach(eventOption => {
              inverseIndex = newAdvanced.findIndex(option => (
                (eventOption.value.includes(option.value) || option.value.includes(eventOption.value)) &&
                eventOption.type !== option.type)
              );
              if (inverseIndex > -1) {
                newAdvanced.splice(inverseIndex, 1);
                newAdvanced.push(eventOption);
                updatedRule[input] = newAdvanced;
                return;
              }
            })
            if (inverseIndex === -1) {
              updatedRule[input] = event;
            }
            break;
          default:
            updatedRule[input] = event;
        }
        this.$emit('rule-change', updatedRule);
      },
      handleTimeLimitChange(value, mode) {
        let updatedRule = _.cloneDeep(this.rule);
        if (mode === 'value'){
          updatedRule.time_limit.value = value;
        } else if (mode === 'type'){
          updatedRule.time_limit.type = value;
          if(value === "minutesPerDay" && this.rule.time_limit.value > 1440){
            updatedRule.time_limit.value = 1440;
          } else if(value === "hoursPerWeek" && this.rule.time_limit.value > 168){
            updatedRule.time_limit.value = 168;
          }
        }
        this.$emit("rule-change", updatedRule);
      }
    }
  }
</script>

<style scoped lang="scss">
  .time-row {
    align-items: flex-end;
  }
  .time-select {
    margin-bottom: 14px;
  }
  .services-word {
    color: #5b136d;
  }
  .mac-word {
    color: #51a427;
  }
  .devices-word {
    color: #2c6fb4;
  }
  .ipv4-word {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
  .urls-domain-word {
    color: #51a427;
  }
  .not-word {
    color: #c15050;
  }
  .reg-word {
    color: #2c6fb4;
  }
  .not-reg-word {
    color: #8f2ca8;
  }
</style>
