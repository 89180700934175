<template>
  <card class="firewall__main-card padding-1">
    <h6 class="policy-card-title">
        <svgicon class="icon" icon="rule-actions" width="16" height="16" />
        <span style="padding-left:1em">{{t('rule actions')}}</span>
    </h6>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('status')">
        <div class="item-alignment">
          <n-switch
            v-model='status'
            @input="handleChange($event, 'status')"
            color="allot"
          ></n-switch>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="120px" :label="t('action')" prop='action'>
        <div class="item-alignment">
          <el-select
            :value="rule.action.type"
            placeholder="Select an action"
            @input="handleChange($event, 'type')"
            class="rule-action-select"
          >
            <el-option
              v-for="option in actionOptions"
              :key="option.value"
              :label="t(option.label)"
              :value="option.value"
              :disabled="option.disabled">
            </el-option>
          </el-select>
        </div>
        <div class="item-alignment">
          <el-input-number
            class="input-number-width"
            v-if="rule.action.type === 'timeout'"
            :controls="false"
            :value="rule.action.value"
            @input="handleChange($event, 'value')"
          ></el-input-number>
        </div>
      </el-form-item>
    </div>
    <div class="row space-around">
      <el-form-item class="form-item" label-width="150px" :label="t('log activity')">
        <div class="item-alignment">
          <el-checkbox
            :value='rule.log_activity'
            @input="handleChange($event, 'log_activity')"
            placeholder='Rule name'
          ></el-checkbox>
        </div>
      </el-form-item>
    </div>
  </card>
</template>

<script>
  import { FormItem, Checkbox, Select, Option, InputNumber } from 'element-ui';
  import Switch from '@/components/Switch';
  export default {
    name: 'main-firewall-config-card',
    components: {
      'el-checkbox': Checkbox,
      'el-form-item': FormItem,
      'n-switch': Switch,
      'el-select': Select,
      'el-option': Option,
      'el-input-number': InputNumber
    },
    data() {
      return {
        actionOptions: [
          {value: '', label: 'Select an action', disabled: true},
          {value: 'allow', label: 'Allow'},
          {value: 'drop', label: 'Drop'},
          {value: 'reject', label: 'Reject'},
          {value: 'timeout', label: 'Timeout'}
        ]
      }
    },
    props: {
      rule: {
        type: Object,
        default: () => {
          return { log_activity: true, status: false, action: {type: 'allow'} }
        }
      }
    },
    computed: {
      status: {
        get() { return this.rule.status },
        set(newRuleStatus) {
          let updatedRule = JSON.parse(JSON.stringify(this.rule));
          updatedRule.status = newRuleStatus;
          this.$emit('rule-change', updatedRule);
        }
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = JSON.parse(JSON.stringify(this.rule));
        if (input === 'type') {
          updatedRule.action[input] = event;
          updatedRule.action.value = undefined;
        } else if (input === 'value') updatedRule.action.value = event;
        else updatedRule[input] = event;
        this.$emit('rule-change', updatedRule);
        const type = updatedRule.action.type;
        const value = updatedRule.action.value;
      }
    }
  }
</script>

<style lang='scss'>
  .firewall__main-card.card {
    font-family: "AmpleSoft Pro Medium", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    border-radius: 0.5em;
    .card-body { margin-top: 1em; }
    .form-item { width: 70%; }
    @media (max-width: 1700px) {
      .main-label-position {
        label {
          width: auto!important;
          float: none;
          display: inline-block;
          text-align: left;
          padding: 0;
          margin: 0;
        }
        div { margin-left: 0!important; }
      }
    }
  }
</style>
