<template>
  <service-policies
    :columns="columns"
    iconName="firewall-configuration"
    containerId="div-firewall"
    storeModuleName="firewall"
    serviceName="Firewall"
    :createButtonPermissions='[{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}]'
    :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/isp-strategy' }]"
  ></service-policies>
</template>

<script>
  import ServicePolicies from '@/components/ServicePolicies/ServicePolicies';
  import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
  import Tags from '@/components/Tables/Formatters/Tags';
  import SchedulerFormatter from '@/components/Tables/Formatters/Scheduler';

  export default {
    name: 'page-firewall',
    components: {
        'service-policies': ServicePolicies
    },
    data() {
      return {
        columns: [
          { prop: 'rule_name', label: 'Rule name', minWidth: 130 },
          { prop: 'status', label: 'Status', minWidth: 100, formatter: Switch, visible_for_permissions: [{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}] },
          { prop: 'source', label: 'Source', minWidth: 180, formatter: Tags, align: 'center' },
          { prop: 'destination', label: 'Destination', minWidth: 180, formatter: Tags, align: 'center' },
          { prop: 'protocol', label: 'Protocol', minWidth: 120, formatter: Tags, align: 'center' },
          { prop: 'hosts', label: 'Host', minWidth: 120, formatter: Tags, align: 'center' },
          { prop: 'ip_protocol', label: 'IP Protocol', minWidth: 145, formatter: Tags, align: 'center' },
          { prop: 'action', label: 'Action', minWidth: 100, formatter: Tags, align: 'center' },
          { prop: 'schedule', label: 'Schedule', minWidth: 200, formatter: SchedulerFormatter, align: 'center' },
        ]
      }
    }
  }
</script>

<style>
</style>