<template>
  <service-policies
    :columns="columns"
    iconName="autoNotices-configuration"
    containerId="div-autoNotices"
    storeModuleName="autoNotices"
    serviceName="Autonotice"
    servicePath="AutoNotices"
    :createButtonPermissions='[{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}, 
    {actions: ["GET"], path: "/subsystems/ns/autonotices/redirections"}, {actions: ["GET"], path: "/subsystems/ns/autonotices/banners"}, 
    {actions: ["GET"], path: "/subsystems/ns/autonotices/smartbanners"}, {actions: ["GET"], path: "/isp-internal-categories"}, 
    {actions: ["GET"], path: "/isp-autonotices-packages"}]'
    :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/isp-strategy' }]"
  ></service-policies>
</template>

<script>
  import ServicePolicies from '@/components/ServicePolicies/ServicePolicies';
  import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
  import Tags from '@/components/Tables/Formatters/Tags';
  import UrlTags from '@/components/Tables/Formatters/UrlTags';
  import SchedulerFormatter from '@/components/Tables/Formatters/Scheduler';

  export default {
    name: 'page-autoNotices',
    components: {
        'service-policies': ServicePolicies
    },
    data() {
      return {
        columns: [
          { prop: 'rule_name', label: this.t('rule_name'), minWidth: 175 },
          { prop: 'status', label: this.t('status'), minWidth: 100, formatter: Switch, visible_for_permissions: [{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}] },
          { prop: 'source', label: this.t('source'), minWidth: 180, formatter: Tags, align: 'center' },
          { prop: 'category', label: this.t('category'), minWidth: 145, formatter: Tags, align: 'center' },
          { prop: 'frequency_text', label: this.t('frequency'), minWidth: 145, formatter: Tags, align: 'center' },
          { prop: 'defined_urls', label: this.t('defined_urls'), minWidth: 145, formatter: UrlTags, align: 'center' },
          { prop: 'allowed_forbidden', label: this.t('A&F-lists'), minWidth: 120, formatter: Tags, align: 'center' },
          { prop: 'action', label: this.t('action'), minWidth: 145, formatter: Tags, align: 'center' },
          { prop: 'schedule', label: this.t('schedule'), minWidth: 250, formatter: SchedulerFormatter, align: 'center' },
        ]
      }
    }
  }
</script>

<style>
</style>