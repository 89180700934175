<template>
  <service-policy-edit
    serviceName="ads-free"
    storeModuleName="adsFree"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
  >
    <template v-slot:main-card="slotProps">
      <main-ads-free-config-card
        :rule="slotProps.rule"
        @rule-change="slotProps.handleRuleChange"
      ></main-ads-free-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule="slotProps.rule"
        :accounts="accounts"
        :categoryOptions="categoryOptions"
        @rule-change="slotProps.handleRuleChange"
        @search-suggestions="searchSuggestions"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
import Vue from "vue";
import { Form, FormItem, Input, Button } from "element-ui";
import ServicePolicyEdit from "@/components/ServicePolicies/ServicePolicyEdit";
import MainCard from "./components/MainCard";
import RuleConfigCard from "./components/RuleConfigCard";
// import { generateScreenshot } from "@/util/generateScreenshot";
import { mapState } from "vuex";

export default {
  name: "ads-free-edit",
  components: {
    "el-form": Form,
    "el-input": Input,
    "el-button": Button,
    "el-form-item": FormItem,
    "main-ads-free-config-card": MainCard,
    "rule-config-card": RuleConfigCard,
    "service-policy-edit": ServicePolicyEdit,
  },

  computed: {
    ...mapState({
      accountsFromState: (state) => state.accountManagement.accounts.accounts,
    }),
  },
  data() {
    return {
      ruleId: undefined,
      rule: {
        rule_name: "",
        status: false,
        action: {
          type: "bypass",
          other: {
            block_only_ads_pop_ups: false,
            freeze_gifs_animation: false,
            hide_banners: false,
          },
        },
        log_activity: true,
        source: [],
        destination: [],
        event: { status: false },
        onlyDuringConnection: true,
        schedule: {
          weekdays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
          startTimeSlot: "",
          endTimeSlot: "",
          startDateSlot: "",
          endDateSlot: "",
        },
        category: [],
        defined_urls: [],
      },
      accounts: [],
      categoryOptions: [],
    };
  },
  methods: {
    async getCaterories() {
      try {
        this.$store.dispatch(
          "networksecure/adsFree/getCategories",
          this.ruleId
        );
        this.categoryOptions = this.$store.getters[
          "networksecure/adsFree/getCategories"
        ];
      } catch (e) {
        console.error(`Error getting ads free categories --> `, e);
      }
    },
    actionValidator(rule, value, callback) {
      if (value.type && value.type !== "") callback();
      else callback(new Error(this.t("please select an action")));
    },
    searchSuggestions(data) {
      const queryString = data.queryString;
      const query = {
        field_name: "account_id",
        field_operator: "sw",
        field_value: queryString,
        sort: "desc",
      };
      this.$store.dispatch("accountManagement/accounts/getAccounts", {
        query,
      });
      const storedAccounts = Array.from(this.accountsFromState.keys());
      let accounts = [];
      storedAccounts.forEach((account) =>
        accounts.push({ value: account, type: "account" })
      );
      this.accounts = accounts.slice(0, 10);
      data.done();
    },
  },
  async created() {
    const ruleId = this.$route.params.pathMatch;
    if (ruleId && ruleId !== "") this.ruleId = ruleId;
    this.getCaterories();
  },
};
</script>
