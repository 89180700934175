<template>
  <card class="padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            {{this.t('Add one or more')}} <span class="devices-word">{{this.t('devices')}}</span>,
             <span class="ipv4-word">{{this.t('ipv4')}}</span> {{this.t('or')}} <span class="ipv6-word">{{this.t('ipv6')}}</span> {{this.t("with or without mask. Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :checks="['ip', 'device']"
            :inputType="'Source'"
            :value='rule.source'
            :placeholder="t('source')"
            :options='devicesAndServices'
            @change="handleChange($event, 'source')"
            @search-suggestions="$emit('search-suggestions', $event)"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('threats')" class="label-in-top">
          <p>{{ this.t('ab_threats_help') }}</p>
          <autocomplete-tag-input
            :value='rule.threat'
            :options='categoryOptions'
            :placeholder="t('threats')"
            @change="handleChange($event, 'threat')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('A&F-lists')" class="label-in-top">
          <p>{{ this.t('A&F_help') }}</p>
          <el-radio-group v-model="rule.allowed_forbidden">
            <el-radio-button label="none">{{ t('none') }}</el-radio-button>
            <el-radio-button label="allowed_list">{{ t('if-in-allowed') }}</el-radio-button>
            <el-radio-button label="forbidden_list">{{ t('if-in-forbidden') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
  </card>
</template>

<script>
  import { Input, FormItem } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'el-form-item': FormItem,
      'autocomplete-tag-input': AutocompleteTagInput
    },
    props: {
      rule: {
        type: Object,
        default: () => {}
      },
      devicesAndServices: {
        type: Array,
        default: () => []
      },
      categoryOptions: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        // Threats input show the translation, so we need to convert to get the real value to send to the backend
        if (input === 'threat') {
          let tempEvent = event.map(element => element.value ? element.value : element);
          let selected = this.categoryOptions.filter(option => tempEvent.includes(option.value));
          updatedRule[input] = selected;
        } else {
          updatedRule[input] = event;
        }
        this.$emit('rule-change', updatedRule);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .services-word {
    color: #5b136d;
  }
  .mac-word {
    color: #51a427;
  }
  .devices-word {
    color: #2c6fb4;
  }
  .ipv4-word {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
</style>
