<template>
  <b-card >
    <template v-slot:header>
      <h4>
        <span>{{ t('title') }}</span>
      </h4>
    </template>
    <el-form
      ref="form"
      :model="allowedForbiddenLists"
      
    >
      <div class="row">
        <div class="col-12 col-md-12">
          <el-form-item prop="webAllowed">
            <allowed-forbidden-list
              class="list-card-border"
              inputPlaceholder="Type a web to add"
              :listContent="allowedForbiddenLists.webAllowed"
              validationErrorMessage="Type a valid input"
              @item-added="(event) => handleListChange(event, 'webAllowed')"
              @item-deleted="(event) => handleListChange(event, 'webAllowed')"
            />
          </el-form-item>
        </div>
      </div>
      <b-row>
        <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto">
          <el-button
            type="primary"
            @click="submitForm('form')"
            class="btn btn-round btn-primary"
            style="width: -webkit-fill-available;"
          >{{ t('Save') }}</el-button>
        </b-col>
      </b-row>
    </el-form>

    <b-modal id="modal-new" ref="modal" :title="t('allowed-forbidden-lists')" @ok="getData">
      <div>{{ t('confirmation_text') }}</div>
    </b-modal>

    <template v-slot:footer>
      <div class="hr-grey mb-2" />
      <div @click="cancel" v-if="!updating" style="cursor: pointer;display: inline;">
        <i class="now-ui-icons arrows-1_refresh-69" />
        {{ t('Update now') }}
      </div>
      <div v-else>
        <i class="now-ui-icons loader_refresh spin" />
        {{ t('Updating...') }}
      </div>
    </template>
  </b-card>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import _ from 'lodash';
import AllowedBlockedTable from '@/components/Tables/AllowedBlockedTable.vue';

export default {
  name: "autonotices-allowed-list",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    'allowed-forbidden-list': AllowedBlockedTable
  },
  props: [""],
  data() {
    return {
      allowedForbiddenLists: {
        webAllowed: [],
        webForbidden: []
      },
      updating: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(){
      this.updating = true;
      try {
        this.allowedForbiddenLists = {
          webAllowed: ['someweb.com', 'someweb.org', 'someweb.net'],
          webForbidden: ['forbiddenweb.com', 'forbiddenweb.org', 'forbidenweb.net']
        };
        this.updating = false;
      } catch(e) {
        console.error('Error getting adsfree A&F Lists data--> ', e);
        this.updating = false;
      }
    },
    submitForm(formName) {
      this.updating = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // Send patch to back
          this.updating = false;
        } else {
          console.error("Validation error");
          this.updating = false;
          return false;
        }
      });
    },
    cancel() { this.$refs.modal.show() },
    handleListChange(listContent, listName) {
      let AFLists = _.cloneDeep(this.allowedForbiddenLists);
      AFLists[listName] = listContent;
      this.allowedForbiddenLists = AFLists;
    }
  }
};
</script>