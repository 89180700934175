<template>
  <service-policy-edit
    serviceName="anti-phising"
    storeModuleName="antiPhishing"
    pathName="Malware&Phishing"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
  >
    <template v-slot:main-card="slotProps">
      <main-anti-phishing-config-card
        :rule='slotProps.rule'
        @rule-change='slotProps.handleRuleChange'
        :logEventOptions="eventsListOptions"
      ></main-anti-phishing-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule='slotProps.rule'
        :devices='devices'
        :categoryOptions='categoryOptions'
        @rule-change='slotProps.handleRuleChange'
        @search-suggestions="searchSuggestions"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
  import Vue from 'vue';
  import { Form, FormItem, Input, Button } from 'element-ui';
  import ServicePolicyEdit from '@/components/ServicePolicies/ServicePolicyEdit';
  import MainCard from './components/MainCard';
  import RuleConfigCard from './components/RuleConfigCard';
  // import { generateScreenshot } from "@/util/generateScreenshot";

  export default {
    name: 'anti-phishing-edit',
    components: {
      'el-form': Form,
      'el-input': Input,
      'el-button': Button,
      'el-form-item': FormItem,
      'main-anti-phishing-config-card': MainCard,
      'rule-config-card': RuleConfigCard,
      'service-policy-edit': ServicePolicyEdit
    },
    data() {
      return {
        ruleId: undefined,
        rule: {
          rule_name: '',
          status: false,
          action: { type: 'allow' },
          log_activity: true,
          source: [],
          protocol: "WEB",
          threat: [],
          allowed_forbidden: 'none',
          event: "",
          schedule: {
            weekdays: {
              monday: false, tuesday: false, wednesday: false,
              thursday: false, friday: false, saturday: false, sunday: false
            },
            startTimeSlot: '',
            endTimeSlot: '',
            startDateSlot: '',
            endDateSlot: ''
          }
        },
        devices: [],
        categoryOptions: [
          {option: 'verified_phishing', value: this.t('verified_phishing')},
          {option: 'suspicious_phishing', value: this.t('suspicious_phishing')},
          {option: 'malware', value: this.t('malware')},
          {option: 'spyware', value: this.t('spyware')}
        ],
        eventsListOptions: [],
        urlRegExp: new RegExp(/^(((ht|f)tps?:\/\/)?[a-zA-Z]+((\.[a-zA-Z])?[0-9\-]*[a-zA-Z0-9]+)*(:\d{1,5})?[^\s\b\t\?\=\&]*(#\w+)?(\?([^\s\b\t\?\=\&#]+\=[^\s\b\t\?\=\&#]*\&?)*)?)$/)
      }
    },
    computed: {
      protocolOptions: {
        get() {
          let options = [];
          const servicesNS = this.$store.getters['settings/getServicesNS'];
          if (servicesNS.find(a => a.includes('ContentFilter')).length > 0) options.push({value: 'WEB'});
          // if (servicesNS.find(a => a.includes('SMTPFilter')).length > 0) options.push({value: 'MAIL'});
          return options;
        }
      }
    },
    methods: {
      actionValidator(rule, value, callback) {
        if (value.type && value.type !== 'blockWithThisURL') callback();
        if (value.type && value.type === 'blockWithThisURL' && value.value  && this.urlRegExp.test(value.value))
          callback();
        if (value.type && value.type === 'blockWithThisURL' && value.value  && !this.urlRegExp.test(value.value))
          callback(new Error(this.t('please, introduce a valid url')));
        if (!value.type || value.type === '')
          callback(new Error(this.t('please select an action')));

      },
      async searchSuggestions(data) {
        const queryString = data.queryString;
        const devicesQuery = {
          field_name: 'ns_id',
          field_operator: "sw",
          field_value: queryString,
          sort: 'asc'
        };
        let devices = [];
        const devicesResult = await this.$store.dispatch('accountManagement/devices/getDevices', {query: devicesQuery});
        const devicesMap = new Map(devicesResult.items);
        for (const [key, value] of devicesMap.entries()){
          const deivceNSid = _.get(value, "product_data.NetworkSecure.ns_id")
          if(deivceNSid && deivceNSid !== "-"){
            devices.push({value: deivceNSid, type: 'device'})
          }          
        }
        
        this.devices = devices
          .sort((a, b) => (a.value > b.value) ? 1 : -1)
          .slice(0, 10);
        data.done();
      },
    },
    async created() {
      const ruleId = this.$route.params.pathMatch;
      if (ruleId && ruleId !== '') this.ruleId = ruleId;

      try {
        await this.$store.dispatch('networksecure/getEventsList');
        this.eventsListOptions = _.cloneDeep(this.$store.getters['networksecure/getEventsList']);
      } catch (e) {
        console.error('Error getting events--> ', e);
      }
    }
  }
</script>