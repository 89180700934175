<template>
  <service-policies
    :columns="columns"
    iconName="adsFree-configuration"
    containerId="div-adsFree"
    storeModuleName="adsFree"
    serviceName="AdsFree"
    :createButtonPermissions='[{actions: ["PATCH"], path: "/subsystems/ns/isp-strategy"}]'
    :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/isp-strategy' }]"
  ></service-policies>
</template>

<script>
  import ServicePolicies from '@/components/ServicePolicies/ServicePolicies';
  import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
  import Tags from '@/components/Tables/Formatters/Tags';
  import UrlTags from '@/components/Tables/Formatters/UrlTags';
  import SchedulerFormatter from '@/components/Tables/Formatters/Scheduler';

  export default {
    name: 'page-adsFree',
    components: {
        'service-policies': ServicePolicies
    },
    data() {
      return {
        columns: [
          { prop: 'rule_name', label: 'Name', minWidth: 130 },
          { prop: 'status', label: 'Status', minWidth: 100, formatter: Switch },
          { prop: 'source', label: 'Source', minWidth: 180, formatter: Tags, align: 'center' },
          { prop: 'destination', label: 'Destination', minWidth: 180, formatter: Tags, align: 'center' },
          { prop: 'category', label: 'Category', minWidth: 145, formatter: Tags, align: 'center' },
          { prop: 'defined_urls', label: 'urls', minWidth: 145, formatter: UrlTags, align: 'center' },
          { prop: 'action', label: 'Action', minWidth: 100, formatter: Tags, align: 'center' },
          { prop: 'schedule', label: 'Schedule', minWidth: 200, formatter: SchedulerFormatter, align: 'center' },
        ]
      }
    }
  }
</script>

<style>
</style>