<template>
  <service-policy-edit
    serviceName="contentFilter"
    storeModuleName="contentFilter"
    pathName="ContentFilter"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
    :sslEnabled="sslEnabled"
  >
    <template v-slot:main-card="slotProps">
      <main-contentFilter-config-card
        :rule='slotProps.rule'
        :actionOptions='slotProps.actionOptions'
        :sslActionsValid="slotProps.sslActionsValid"
        @rule-change='slotProps.handleRuleChange'
      ></main-contentFilter-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule='slotProps.rule'
        :devicesAndServices='devicesAndServices'
        :categoryOptions='categoryOptions'
        :httpOptions='httpMethodsOptions'
        :timeLimitOptions='selectTimeLimitOptions'
        :advancedOptions='selectAdvancedOptions'
        :sslEnabled='slotProps.sslEnabled'
        @rule-change='slotProps.handleRuleChange'
        @search-suggestions="searchSuggestions"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
  import Vue from 'vue';
  import { Form, FormItem, Input, Button } from 'element-ui';
  import ServicePolicyEdit from '@/components/ServicePolicies/ServicePolicyEdit';
  import MainCard from './components/MainCard';
  import RuleConfigCard from './components/RuleConfigCard';
  import { mapGetters, mapActions }			 from "vuex";
  import _ from 'lodash';
  // import { generateScreenshot } from "@/util/generateScreenshot";

  export default {
    name: 'contentFilter-edit',
    components: {
      'el-form': Form,
      'el-input': Input,
      'el-button': Button,
      'el-form-item': FormItem,
      'main-contentFilter-config-card': MainCard,
      'rule-config-card': RuleConfigCard,
      'service-policy-edit': ServicePolicyEdit
    },
    data() {
      return {
        ruleId: undefined,
        rule: {
          rule_name: "",
          status: false,
          action: { type: null, value: null },
          log_activity: true,
          source: [],
          categories: [],
          urls: [],
          http_methods: [],
          time_limit: { type: null, value: 0 },
          advanced: [],
          allowed_forbidden: 'none',
          schedule: {
            weekdays: {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false,
            },
            startTimeSlot: "",
            endTimeSlot: "",
            startDateSlot: "",
            endDateSlot: "",
          },
        },
        devicesAndServices: [],
        httpMethodsOptions: [
          { value: "GET" },
          { value: "POST" },
          { value: "PUT" },
          { value: "HEAD" },
          { value: "OPTIONS" },
          { value: "CONNECT" },
          { value: "TRACE" },
          { value: "DELETE" },
        ],
        timeLimitOptions: [
          { value: "minutesPerDay", label: "minutes_per_day" },
          { value: "hoursPerWeek", label: "hours_per_week" },
        ],
        advancedOptions: [
          { type: "included", value: "pinning"},
          { type: "excluded", value: "not: pinning"},
          { type: "included", value: "client certificate request"},
          { type: "excluded", value: "not: client certificate request"},
          { type: "included", value: "extended validation certificate"},
          { type: "excluded", value: "not: extended validation certificate"},
        ],
      }
    },
    computed: {
      ...mapGetters('ispServices/categoryManagement', ['categories']),
      ...mapGetters('networksecure/contentFilter', ['serviceConfiguration']),
      categoryOptions(){
        let cats = this.categories
        if ( !cats) return [];

        return cats.map( (item => ({name: item.isp_internal_category_id, value: this.t(item.isp_internal_category_id)})).bind(this));
      },
      selectAdvancedOptions() {
        return this.advancedOptions.map((option => ({type: option.type, value: option.value, label: this.t(option.value)})).bind(this))
      },
      selectTimeLimitOptions() {
        return this.timeLimitOptions.map((option => ({value: option.value, label: this.t(option.label)})).bind(this))
      },
      sslEnabled() {
        if (!_.isEmpty(this.serviceConfiguration)) {
          const {webfilter: {https_configuration: { enable_ssl_inspection }}} = this.serviceConfiguration;
          return enable_ssl_inspection;
        }
        return false;
      }
    },
    methods: {
      ...mapActions('ispServices/categoryManagement', ['getCategories']),
      actionValidator(rule, value, callback) {
        if (value.type && value.type !== '') callback();
        else callback(new Error(this.t('please select an action')));
      },
      async searchSuggestions(data) {
        const queryString = data.queryString;
        const devicesQuery = {
          field_name: 'ns_id',
          field_operator: "sw",
          field_value: queryString,
          sort: 'asc'
        };
        let devicesAndServices = [];
        const devicesResult = await this.$store.dispatch('accountManagement/devices/getDevices', {query: devicesQuery});
        const devicesMap = new Map(devicesResult.items);
        for (const [key, value] of devicesMap.entries()){
              const deivceNSid = _.get(value, "product_data.NetworkSecure.ns_id")
              if(deivceNSid && deivceNSid !== "-"){
                devicesAndServices.push({value: deivceNSid, type: 'device'})
              }
        }

        // await this.$store.dispatch('getServicesModules', {query: queryString});
        // const servicesResult = this.$store.getters['getServicesModules'];
        // for (const service of servicesResult) devicesAndServices.push({value: service, type: 'service'});

        this.devicesAndServices = devicesAndServices
          .sort((a, b) => (a.value > b.value) ? 1 : -1)
          .slice(0, 10);
        data.done();
      },
    },
    async created() {
      const ruleId = this.$route.params.pathMatch;
      if (ruleId && ruleId !== '') this.ruleId = ruleId;
      await this.getCategories();
      await this.$store.dispatch('networksecure/contentFilter/getServiceConfiguration');
    }
  }
</script>
