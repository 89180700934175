<template>
  <service-policy-edit
    serviceName="anti-virus"
    storeModuleName="antiVirus"
    pathName="AntiVirus"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
  >
    <template v-slot:main-card="slotProps">
      <main-anti-virus-config-card
        :rule='slotProps.rule'
        @rule-change='slotProps.handleRuleChange'
        :logEventOptions="eventsListOptions"
      ></main-anti-virus-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule='slotProps.rule'
        :devicesAndServices='devicesAndServices'
        :categoryOptions='categoryOptions'
        @rule-change='slotProps.handleRuleChange'
        @search-suggestions="searchSuggestions"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
  import Vue from 'vue';
  import { Form, FormItem, Input, Button } from 'element-ui';
  import ServicePolicyEdit from '@/components/ServicePolicies/ServicePolicyEdit';
  import MainCard from './components/MainCard';
  import RuleConfigCard from './components/RuleConfigCard';
  // import { generateScreenshot } from "@/util/generateScreenshot";

  export default {
    name: 'anti-virus-edit',
    components: {
      'el-form': Form,
      'el-input': Input,
      'el-button': Button,
      'el-form-item': FormItem,
      'main-anti-virus-config-card': MainCard,
      'rule-config-card': RuleConfigCard,
      'service-policy-edit': ServicePolicyEdit
    },
    data() {
      return {
        ruleId: undefined,
        rule: {
          rule_name: '',
          status: false,
          action: { type: 'allow' },
          log_activity: true,
          source: [],
          destination: [],
          protocol: "WEB",
          advanced: "none",
          threats: 'infected',
          event:"",
          schedule: {
            weekdays: {
              monday: false, tuesday: false, wednesday: false,
              thursday: false, friday: false, saturday: false, sunday: false
            },
            startTimeSlot: '',
            endTimeSlot: '',
            startDateSlot: '',
            endDateSlot: ''
          }
        },
        devicesAndServices: [],
        categoryOptions: [ {value: this.t('verified virus sources')}, {value: this.t('suspicious virus sources')}, {value: this.t('pharming (only web)')} ],
        eventsListOptions: [],
        urlRegExp: new RegExp(/^(((ht|f)tps?:\/\/)?[a-zA-Z]+((\.[a-zA-Z])?[0-9\-]*[a-zA-Z0-9]+)*(:\d{1,5})?[^\s\b\t\?\=\&]*(#\w+)?(\?([^\s\b\t\?\=\&#]+\=[^\s\b\t\?\=\&#]*\&?)*)?)$/)
      }
    },
    computed: {
      protocolOptions: {
        get() {
          let options = [];
          const servicesNS = this.$store.getters['settings/getServicesNS'];
          if (servicesNS.find(a => a.includes('ContentFilter')).length > 0) options.push({value: 'WEB'});
          // if (servicesNS.find(a => a.includes('SMTPFilter')).length > 0) options.push({value: 'MAIL'});
          return options;
        }
      }
    },
    methods: {
      actionValidator(rule, value, callback) {
        if (value.type && value.type !== '') callback();
        else callback(new Error(this.t('please select an action')));
      },
      async searchSuggestions(data) {
        const queryString = data.queryString;
        const devicesQuery = {
          field_name: 'ns_id',
          field_operator: "sw",
          field_value: queryString,
          sort: 'asc'
        };
        let devicesAndServices = [];
        const devicesResult = await this.$store.dispatch('accountManagement/devices/getDevices', {query: devicesQuery});
        const devicesMap = new Map(devicesResult.items);
        for (const [key, value] of devicesMap.entries()){
              const deivceNSid = _.get(value, "product_data.NetworkSecure.ns_id")
              if(deivceNSid && deivceNSid !== "-"){
                devicesAndServices.push({value: deivceNSid, type: 'device'})
              }          
        }
        // await this.$store.dispatch('getServicesModules', {query: queryString});
        // const servicesResult = this.$store.getters['getServicesModules'];
        // for (const service of servicesResult) devicesAndServices.push({value: service, type: 'service'});
        
        this.devicesAndServices = devicesAndServices
          .sort((a, b) => (a.value > b.value) ? 1 : -1)
          .slice(0, 10);
        data.done();
      },
      async getEventListOptions() {
        try {
          await this.$store.dispatch('networksecure/getEventsList');
          this.eventsListOptions = _.cloneDeep(this.$store.getters['networksecure/getEventsList']);
        } catch (e) { console.error('Error getting events--> ', e) };
      }
    },
    async created() {
      const ruleId = this.$route.params.pathMatch;
      if (ruleId && ruleId !== '') this.ruleId = ruleId;

      this.getEventListOptions();
    }
  }
</script>