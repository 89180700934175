<template>
  <div>
    <page-title-card
      :page-title="t('garbage_timeouts')"
    >
      <template #button>
        <n-button
          type="primary"
          @click.native="submitForm('form')"
          class="btn btn-round btn-primary"
          style="width: -webkit-fill-available"
          :visible_for_permissions="[
              {
                actions: ['PUT'],
                path: '/subsystems/ns/firewall/advanced-configuration',
              },
            ]"
        >{{ t("save") }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <el-form
        ref="form"
        :model="form"
        label-width="140px"
        label-position="top"
        style="padding-left: 30px; padding-right: 30px; padding-top: 30px"
        :rules="rules"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <el-form-item :label="t('tcp_traffic')" prop="tcpTraffic">
              <el-input
                v-model.number="form.tcpTraffic"
                class="form-group no-height form-control-lg input-group"
              ></el-input>
            </el-form-item>

            <el-form-item :label="t('udp_traffic')" prop="udpTraffic">
              <el-input
                v-model.number="form.udpTraffic"
                class="form-group no-height form-control-lg input-group"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item :label="t('icmp_traffic')" prop="icmpTraffic">
              <el-input
                v-model.number="form.icmpTraffic"
                class="form-group no-height form-control-lg input-group"
              ></el-input>
            </el-form-item>

            <el-form-item :label="t('all_other_traffic')" prop="allOtherTraffic">
              <el-input
                v-model.number="form.allOtherTraffic"
                class="form-group no-height form-control-lg input-group"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <b-modal
        id="modal-new"
        ref="modal"
        :title="t('garbage_timeouts')"
        @ok="handleOk" :ok-title="t('accept')" :cancel-title="t('cancel')"
      >
        <div>{{ t("confirmation_text") }}</div>
      </b-modal>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="cancel"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("Update now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("Updating...") }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from "element-ui";
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "firewall-advanced-configuration",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    PageTitleCard
  },
  props: [""],
  data() {
    var checkVal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.t("validation_text")));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error(this.t("validation_text")));
        } else {
          if (value < 5 || value > 50000) {
            callback(new Error(this.t("validation_text")));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      form: {
        tcpTraffic: "",
        udpTraffic: "",
        icmpTraffic: "",
        allOtherTraffic: "",
      },
      rules: {
        tcpTraffic: [{ validator: checkVal, trigger: ["blur", "change"] }],
        udpTraffic: [{ validator: checkVal, trigger: ["blur", "change"] }],
        icmpTraffic: [{ validator: checkVal, trigger: ["blur", "change"] }],
        allOtherTraffic: [{ validator: checkVal, trigger: ["blur", "change"] }],
        updating: false,
      },
    };
  },
  mounted() {
    let _vm = this;

    _vm.updating = true;
    this.$store
      .dispatch("networksecure/firewall/getFirewallAdvancedConfiguration")
      .then((res) => {
        console.log("Instance");
        _vm.form.tcpTraffic = res.tcp_traffic;
        _vm.form.udpTraffic = res.udp_traffic;
        _vm.form.icmpTraffic = res.icmp_traffic;
        _vm.form.allOtherTraffic = res.other_traffic;
        _vm.updating = false;
      })
      .catch((err) => {
        _vm.updating = false;
      });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = {
            firewall: {
              tcp_traffic: this.form.tcpTraffic,
              udp_traffic: this.form.udpTraffic,
              icmp_traffic: this.form.icmpTraffic,
              other_traffic: this.form.allOtherTraffic,
            }
          };
          this.updating = true;
          this.$store
            .dispatch(
              "networksecure/firewall/submitFirewallAdvancedConfiguration",
              config
            )
            .then((res) => {
              this.$store.commit("setSuccess", this.t("save_success"));
              this.updating = false;
            });
        } else {
          console.log("Error submitting");
          this.updating = false;
          return false;
        }
      });
    },
    cancel() {
      // Show confirmation dialog
      this.$refs.modal.show();
    },
    handleOk(bvModalEvt) {
      console.log("OK");
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("networksecure/firewall/getFirewallAdvancedConfiguration")
        .then((res) => {
          //console.log("Instance", res);
          _vm.form.tcpTraffic = res.tcp_traffic;
          _vm.form.udpTraffic = res.udp_traffic;
          _vm.form.icmpTraffic = res.icmp_traffic;
          _vm.form.allOtherTraffic = res.other_traffic;
          _vm.updating = false;
        })
        .catch((err) => {
          _vm.updating = false;
        });
    },
  },
  watch: {},
  computed: {},
};
</script>
<style>
</style>
