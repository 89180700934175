<template>
  <!-- <card> -->
    <div :id="`page-${serviceName}-edit`" :ref="`page-${serviceName}-edit`" class="service-policy-edit">
    <el-form v-if="rule" :model="{...rule}" :ref="formRef" label-width="120px" label-position="left" :rules='formRules'>
      <div slot="header">
        <h4 class="card-title">{{ t(serviceName) }}</h4>
      </div>
      <div class="row space-around">
        <div class="col-md-12">
          <!-- Name -->
          <card>
            <div class="row space-around baseline">
              <div class="col-md-8 col-lg-9">
                <el-form-item :label="t('rule name')" prop="rule_name" label-width="140px">
                  <div class="item-alignment">
                    <el-input
                      :value='rule.rule_name'
                      :class="errorMessage ? 'errorMessage': ''"
                      @input="handleRuleNameChange($event)"
                      :disabled="ruleId ? true : false"
                      :placeholder="t('rule name')"
                      :maxlength="maxlenRuleName"
                    ></el-input>
                    <p v-if='errorMessage' class="errorMessage">{{errorMessage}}</p>
                  </div>
                </el-form-item>
              </div>
              <div class="col-md-4 col-lg-3 grid space-around">
                <div class="col-md-12">
                  <n-button class="button-width rule-save-button" type="primary" @click.native='handleSave(formRef)' :visible_for_permissions="[{ actions: ['PATCH', 'POST'], path: '/subsystems/ns/isp-strategy' }]">{{t('save')}}</n-button>
                </div>
                <div class="col-md-12">
                  <n-button class="button-width rule-cancel-button" @click.native='handleCancel'>{{t('back')}}</n-button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row space-around">
        <!-- Rule configuration -->
        <div class="col-xs-12 col-lg-12 col-xxl-6">
          <!-- Rule Actions -->
          <slot
            name="main-card"
            v-bind:handleRuleChange="handleRuleChange"
            v-bind:rule="rule"
            :sslActionsValid='sslActionsValid'
            :actionOptions='actionOptions'
          ></slot>
          <!-- Schedule config -->
          <schedule-config-card v-if="showSchedule"
            :scheduleProp='rule.schedule'
            @schedule-change='handleScheduleChange'
          ></schedule-config-card>
        </div>
        <div class="col-xs-12 col-lg-12 col-xxl-6 space-around">
          <!-- Rule specific config -->
          <slot
            name="rule-config-card"
            v-bind:handleRuleChange="handleRuleChange"
            v-bind:rule="rule"
            :sslEnabled='sslEnabled'
          ></slot>
        </div>
      </div>
    </el-form>
    <b-modal id="modal-warning"
             ref="modal-warning"
             v-model="showConfirm"
             @ok="handleConfirm"
             @cancel="showConfirm = !showConfirm"
             :title="t('warning')"
             :ok-title="t('save')"
             :cancel-title="t('cancel')">
      {{dangerousRuleMessage}}
    </b-modal>
    <b-modal id="modal"
             ref="modal"
             @ok="backCancel"
             @cancel="backConfirm"
             :title="t('discard_changes_title')"
             :ok-title="t('keep_editing')"
             :cancel-title="t('discard')">
        {{ t('discard_changes_content') }}
      </b-modal>
  <!-- </card> -->
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Form, FormItem, Input, Button } from 'element-ui';
  import Modal from '@/components/Modal';
  import ScheduleConfigCard from '@/components/Schedule/ScheduleConfigCard';
  import _ from "lodash";
  // import { generateScreenshot } from "@/util/generateScreenshot";

  export default {
    name: 'service-policy-edit',
    components: {
      'modal': Modal,
      'el-form': Form,
      'el-input': Input,
      'el-button': Button,
      'el-form-item': FormItem,
      'schedule-config-card': ScheduleConfigCard
    },
    props: {
      serviceName: {
        type: String,
        default: '',
        required: true
      },
      storeModuleName: {
        type: String,
        default: '',
        required: true
      },
      pathName: {
        type: String,
        default: '',
        required: true
      },
      defaultRule: {
        type: Object,
        required: true,
        default: () => {}
      },
      ruleId: {
        type: String,
        default: undefined
      },
      actionValidator: {
        type: Function
      },
      editionPath: {
        type: String,
        default: () => '/ns'
      },
      showSchedule: {
        type: Boolean,
        default: () => true
      },
      storePath: {
        type: String,
        default: () => 'networksecure'
      },
      maxlenRuleName: {
        type: Number,
        default: () => 50
      },
      sslEnabled: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        internalRule: undefined,
        errorMessage: undefined,
        showConfirm: false,
        formRules: {
          rule_name: [
            { required: true, pattern: '^[a-zA-Z0-9_\-]+$', message: this.t('please input rule name'), trigger: 'change' },
          ],
          action: [ { required: true, trigger: ['change', 'blur'], validator: this.actionValidator } ],
          'schedule.startTimeSlot': [{
            trigger: ['change', 'blur'], message: this.t('please, complete the range'), validator: this.startTimeValidator
          }],
          'schedule.endTimeSlot': [{
            trigger: ['change', 'blur'], message: this.t('please, complete the range'), validator: this.endTimeValidator
          }],
          'schedule.startDateSlot': [{
            trigger: ['change', 'blur'], message: this.t('please, complete the range'), validator: this.startDateValidator
          }],
          'schedule.endDateSlot': [{
            trigger: ['change', 'blur'], message: this.t('please, complete the range'), validator: this.endDateValidator
          }],
        },
        dangerousPropNames: {
          'contentFilter': ['schedule', 'source', 'categories', 'urls', 'http_methods', 'time_limit', 'allowed_forbidden' ,'advanced'],
          'autonotices': ['schedule', 'source', 'category', 'defined_urls', 'frequency', 'allowed_forbidden'],
          'anti-phising': ['schedule', 'source', 'threat', 'allowed_forbidden'],
          'antibotnet': ['schedule', 'source', 'threat', 'allowed_forbidden'],
          'anti-virus': ['schedule', 'source', 'threats', 'destination'],
          'firewall': ['schedule', 'source', 'destination', 'protocol', 'hosts', 'ip_protocol'],
          'dnscf': ['source', 'categories', 'urls']
        }
      }
    },
    computed: {
      formRef: {
        get() {
          return `${this.serviceName}-form`;
        }
      },
      capitalizedModuleName: {
        get() {
          return this.storeModuleName.charAt(0).toUpperCase() + this.storeModuleName.slice(1);
        }
      },
      rule: {
        get() {
          if (this.internalRule === undefined){
            this.internalRule = _.cloneDeep(this.defaultRule);
          }
          return this.internalRule;
        },
      },
      isFrequencyDefault() {
        return this.internalRule.frequency
          && this.internalRule.frequency.display
          && this.internalRule.frequency.display.when === 'always'
          && !this.internalRule.frequency.delay;
      },
      isTimeLimitDefault() {
        return this.internalRule.time_limit && !this.internalRule.time_limit.value;
      },
      sslActionsValid() {
        if(this.internalRule.http_methods) {
          return this.internalRule.http_methods.length === 1 && this.internalRule.http_methods.includes('CONNECT');
        }
        return false;
      },
      actionOptions() {
        let options = [
          {value: "allow", label: this.t("tags-formatter.allow")},
          {value: "block", label: this.t("tags-formatter.block")},
          {value: "blockWithThisURL", label: this.t("tags-formatter.block_with_url")},
          {value: "block_override", label: this.t("tags-formatter.block_override")},
        ];
        if (this.sslEnabled) {
          options.push(
            {value: "ssl_termination", label: this.t("tags-formatter.ssl_termination"), disabled: !this.sslActionsValid},
            {value: "bypass_ssl_termination", label: this.t("tags-formatter.bypass_ssl_termination"), disabled: !this.sslActionsValid}
          )
        }
        return options;
      },
      dangerousRuleMessage() {
        let dangerousRuleText = this.t('dangerous_rule_message_generic');
        const actionType = this.rule.action.type;
        if (this.serviceName === 'autonotices') {
          if (actionType === 'redirect') {
            dangerousRuleText = this.t('dangerous_rule_message_autonotice_redirect_to');
          } else if (actionType === 'allow') {
            dangerousRuleText = this.t('dangerous_rule_message_autonotice_allow');
          }
        }
        return dangerousRuleText;
      },
      isDangerousServiceAndAction() {
        const notDangerousServiceConditions = {
          "notDangerousAutonoticeRule": this.serviceName === 'autonotices' && !['redirect', 'allow'].includes(this.rule.action.type),
          "notDangerousAntivirusRule": this.serviceName === 'anti-virus'
        }
        return !Object.values(notDangerousServiceConditions).some(value => value);
      }
    },
    methods: {
      filterDangerousProps(rule) {
        const dangerousPropNamesForServiceType = this.dangerousPropNames[this.serviceName] ? this.dangerousPropNames[this.serviceName] : [];
        const dangerousRuleProps = Object.keys(rule)
          .filter(propName => dangerousPropNamesForServiceType.includes(propName))
          .reduce((obj, propName) => {
            obj[propName] = rule[propName];
            return obj;
          }, {});
        return dangerousRuleProps;
      },
      isEqualDangerousProps(internalDangerousProps, defaultDangerousProps) {
        const notEqualDangerousProps = [];
        if (this.isFrequencyDefault) notEqualDangerousProps.push('frequency');
        if (this.isTimeLimitDefault) notEqualDangerousProps.push('time_limit');

        return _.isEqual(
          _.omit(internalDangerousProps, notEqualDangerousProps),
          _.omit(defaultDangerousProps, notEqualDangerousProps)
        );
      },
      startTimeValidator(rule, value, callback) {
        if (value === '' && this.internalRule.schedule.endTimeSlot === '') callback();
        else if (value !== '' && this.internalRule.schedule.endTimeSlot !== '') callback();
        else callback(new Error(this.t('please, complete the range')));
      },
      endTimeValidator(rule, value, callback) {
        if (value === '' && this.internalRule.schedule.startTimeSlot === '') callback();
        else if (value !== '' && this.internalRule.schedule.startTimeSlot !== '') callback();
        else callback(new Error(this.t('please, complete the range')));
      },
      startDateValidator(rule, value, callback) {
        if (value === '' && this.internalRule.schedule.endDateSlot === '') callback();
        else if (value !== '' && this.internalRule.schedule.endDateSlot !== '') callback();
        else callback(new Error(this.t('please, complete the range')));
      },
      endDateValidator(rule, value, callback) {
        if (value === '' && this.internalRule.schedule.startDateSlot === '') callback();
        else if (value !== '' && this.internalRule.schedule.startDateSlot !== '') callback();
        else callback(new Error(this.t('please, complete the range')));
      },
      handleRuleChange(data) {
        this.internalRule = data
      },
      handleScheduleChange(data) {
        this.internalRule.schedule = data;
        const slotFields = [
          'schedule.startDateSlot', 'schedule.endDateSlot', 'schedule.startTimeSlot', 'schedule.endTimeSlot'
        ];
        this.$refs[this.formRef].validateField(slotFields);
      },
      handleRuleNameChange(data) {
        this.errorMessage = undefined;
        this.internalRule.rule_name = data;
      },
      async getEditingRule() {
        try {
          let editingRule = this.$store.getters[`${this.storePath}/${this.storeModuleName}/getRuleById`](this.ruleId);
          if (!editingRule){
            let path = this.$route.fullPath.split('/')
            path = path.splice(0,path.length-2)
            this.$router.replace({path: path.join('/')})
          }
          else{
            if (editingRule.schedule === 'always')
              editingRule.schedule =  {
                weekdays: {
                  monday: false, tuesday: false, wednesday: false,
                  thursday: false, friday: false, saturday: false, sunday: false
                },
                startTimeSlot: '',
                endTimeSlot: '',
                startDateSlot: '',
                endDateSlot: ''
              };
            if (editingRule.time_limit && !editingRule.time_limit.value) {
              editingRule.time_limit = {
                value: 0,
                type: null
              }
            }
            this.internalRule = editingRule;
          }
        } catch(e) {
          console.error(`Error getting rule ${this.ruleId}--> `, e);
        }
      },
      dangerousRule() {
        const internalDangerousProps = this.filterDangerousProps(this.internalRule);
        const defaultDangerousProps = this.filterDangerousProps(this.defaultRule);
        const dangerousRulePropsChangedFromDefault = !this.isEqualDangerousProps(internalDangerousProps, defaultDangerousProps);
        if (!dangerousRulePropsChangedFromDefault && this.isDangerousServiceAndAction) {
          return true;
        }
        return false;
      },
      handleSave() {
        if (this.dangerousRule()) this.showConfirm = !this.showConfirm;
        else this.saveRule();
      },
      handleConfirm() {
        this.showConfirm = !this.showConfirm;
        this.saveRule();
      },
      async checkIfUniqueName() {
        await this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/get${this.capitalizedModuleName}RulesData`);
        const rules = this.$store.getters[`${this.storePath}/${this.storeModuleName}/get${this.capitalizedModuleName}Rules`];
        const isUnique = rules.filter(
          rule => rule.rule_name === this.internalRule.rule_name
        ).length === 0;
        if (!isUnique) this.errorMessage = this.t('rule name must be unique');
        return isUnique;
      },
      saveRule() {
        this.$refs[this.formRef].validate(
          async (valid, errors) => {
            if(valid) {
              if (this.ruleId) {
                try {
                  await this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/update${this.capitalizedModuleName}Rule`, this.internalRule);
                  this.$store.commit('setSuccess', this.t('save_success'));
                 } catch(err) { }
              } else if (await this.checkIfUniqueName()) {
                // create a new rule

                /** This piece of code is temp, only for use case tests */
                // const printable = generateScreenshot(this.$refs['page-firewall-edit'], this.rule.rule_name)
                /** End of temp code */
                try {
                  await this.$store.dispatch(`${this.storePath}/${this.storeModuleName}/create${this.capitalizedModuleName}Rule`, this.rule);
                  this.$store.commit('setSuccess', this.t('save_success'));
                  this.$router.replace({path: `${this.editionPath}/${this.pathName}/Policies`});
                } catch(err) { }
              }
            } else {
              this.$store.commit('setError', this.t('errors_in_form'));
              console.error('Validation Error!--> ', errors);
            }
          }
        );
      },
      handleCancel() {
        this.$refs.modal.show();
      },
      backConfirm(){
        this.$router.back();
      },
      backCancel(){
        this.$refs.modal.hide();
      },
    },
    async created() {
      if (this.ruleId) this.getEditingRule();
    },
  }
</script>

<style lang="scss">
  .service-policy-edit {
    margin-top: 3em;
    margin-left: 3em;
    margin-right: 3em;
    .grid {
      display: grid;
      padding: 1em;
    }
    .rule-save-button,
    .rule-save-button:focus {
      color: white;
      border: none;
      background-color: #f96332;
      margin: .25em 0;
    }
    .rule-save-button:hover {
      color: black;
      border: none;
      background-color: #fdb096;
      margin: .25em 0;
    }
    .rule-cancel-button,
    .rule-cancel-button:focus {
      color: white;
      border: none;
      background-color: #606060;
      margin: .25em 0;
    }
    .rule-cancel-button:hover {
      color: black;
      border: none;
      background-color: #bdbdbd;
      margin: .25em 0;
    }
    .policy-card-title { margin-bottom: 2em; }
    div.el-form-item {
      label { font-size: 1em; }
      p {
        font-size: .7em;
        color: #9A9A9A;
        margin: 0;
        line-height: 1.3em;
      }
    }
  }
</style>
